import { upload, get } from "../common/axios";

const getDocuments = (params) => {
    return get(`/api/documents/get-by-client?rfc=${params.rfc}`).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

const uploadDocuments = (data) => {
    return upload('/api/documents/upload/', data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

export { getDocuments, uploadDocuments }