import React, { Component } from 'react';
import { Col, Tooltip, Table, Card, CardBody, Row } from "reactstrap";
import { Loading, Warning, Close } from '../../common/sweetalert'

import moment from 'moment';
import 'moment-timezone';

import PDF from "../../assets/images/icons/pdf.png"
import XML from "../../assets/images/icons/xml1.png"

class LatestCFDIs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: this.props.width,
            height: 0
        };

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() { this.setState({ width: window.innerWidth, height: window.innerHeight }); }

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {
        let cfdi_list = [];
        this.props.cfdiList.filter(elem => {
            if (elem.CFDIList) {
                cfdi_list = elem.CFDIList;
            }
            return elem;
        });

        const downloadFile = async (path, id, ext) => {
            Loading('Descargando archivo')
            try {
                const url = `https://clientes.tabimax.com.mx/private/download?file=${path}&id=${id}.${ext}`;
                const response = await fetch(url);
                if (!response.ok) {
                    return Warning('Archivo inexistente', 'Error al descargar el archivo');
                }

                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${id}.${ext}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                Close();
            } catch (error) {
                Warning('', 'No fue posible descargar el archivo')
            }

        }

        return (
            <React.Fragment>
                <Col lg={12}>
                    <Card>
                        {
                            cfdi_list.length ?

                                this.state.width >= 768 ?
                                    <CardBody style={{ height: '455px', overflowX: 'auto' }}>
                                        <h4 className="card-title mb-4">Últimos CFDI emitidos</h4>
                                        <div className="table-responsive">
                                            <Table id="tech-companies-1" bordered striped responsive>
                                                <thead className="table-primary">
                                                    <tr>
                                                        <th>#</th>
                                                        <th>Movimiento</th>
                                                        <th>Cuenta</th>
                                                        <th className='text-center'>CFDI</th>
                                                        <th className='text-center'>Acuse</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {cfdi_list.map((cte, key) => (
                                                        <tr
                                                            key={key}
                                                        >
                                                            <td>{(key + 1)}</td>
                                                            <td>{cte.MovId || 'S/D'}</td>
                                                            <td>{cte.Cliente || 'S/D'}</td>
                                                            <td className='text-center'>
                                                                {
                                                                    cte.pdf ? 
                                                                        <span style={{ marginRight: 10 }} className="cursor-pointer">
                                                                            <Tooltip placement="top" isOpen={this.state.tttop} target="TooltipTopCfdiPdf" toggle={() => this.setState({ tttop: !this.state.tttop })}>Descargar PDF</Tooltip>
                                                                            <img id="TooltipTopCfdiPdf" src={PDF} alt="pdf" height="30" onClick={() => downloadFile(cte.pdf, cte.mov, 'pdf')} />
                                                                        </span>
                                                                    : null
                                                                }
                                                                {
                                                                    cte.xml ? 
                                                                        <span className="cursor-pointer">
                                                                            <Tooltip placement="top" isOpen={this.state.tttop1} target="TooltipTopCfdiXml" toggle={() => this.setState({ tttop1: !this.state.tttop1 })}>Descargar XML</Tooltip>
                                                                            <img id="TooltipTopCfdiXml" src={XML} alt="xml" height="30" onClick={() => downloadFile(cte.xml, cte.mov, 'xml')} />
                                                                        </span>
                                                                    : null
                                                                }
                                                            </td>
                                                            <td className='text-center'>
                                                                {
                                                                    cte.aplicaid.length ?
                                                                        cte.aplicaid.map((item, index) => (
                                                                            item.url ? 
                                                                                <React.Fragment key={index}>
                                                                                    <Tooltip
                                                                                        placement="top"
                                                                                        isOpen={this.state[`tttop${index + 2}`]}
                                                                                        target={`TooltipTopCfdiPdf${index + 2}`}
                                                                                        toggle={() => this.setState({ [`tttop${index + 2}`]: !this.state[`tttop${index + 2}`] })}
                                                                                    >
                                                                                        Descargar PDF
                                                                                    </Tooltip>
                                                                                    <img
                                                                                        id={`TooltipTopCfdiPdf${index + 2}`}
                                                                                        src={PDF}
                                                                                        alt="pdf"
                                                                                        height="30"
                                                                                        style={{ cursor: "pointer" }}
                                                                                        onClick={() => downloadFile(item.url, item.AplicaID, 'pdf')}
                                                                                    />
                                                                                </React.Fragment>
                                                                            : null
                                                                        ))
                                                                        : <>Sin archivos</>
                                                                }
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </CardBody>
                                    :
                                    cfdi_list.map((cfdi, key) => (
                                        <Card className="font-size-12" key={key}>
                                            <CardBody style={{ padding: "7px 20px" }}>
                                                <Row>
                                                    <Col xs={4} sm={4} md={4} style={{ padding: "0 2px 0 0" }}>
                                                        <div>Emisión</div>
                                                        <div>{moment(cfdi.emission_date).format('DD/MM/yyyy')}</div>
                                                    </Col>
                                                    <Col xs={5} sm={5} md={5} className="text-center" style={{ padding: "0 1px" }}>
                                                        <div>{cfdi.MovId}</div>
                                                    </Col>
                                                    <Col xs={3} sm={3} md={3} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                                                        <div>Vencimiento</div>
                                                        <div>{moment(cfdi.expiration_date).format('DD/MM/yyyy')}</div>
                                                    </Col>
                                                </Row>
                                                <hr style={{ margin: "5px -20px 10px" }} />
                                                <Row>
                                                    <Col xs={4} sm={4} md={4} style={{ padding: "0 2px 0 0" }}>
                                                        <span style={{ verticalAlign: "sub" }}> Factura</span><br />
                                                        {
                                                            cfdi.pdf ? 
                                                                <img src={PDF} alt="pdf" height="20" onClick={() => downloadFile(cfdi.pdf, cfdi.mov, 'pdf')} />
                                                            : null
                                                        }
                                                    </Col>
                                                    <Col xs={4} sm={4} md={4} className="text-center" style={{ padding: "0 1px" }}>
                                                        <span style={{ verticalAlign: "sub" }}> XML</span><br />
                                                        {
                                                            cfdi.xml ?
                                                                <img src={XML} alt="xml" height="20" onClick={() => downloadFile(cfdi.xml, cfdi.mov, 'xml')} />
                                                            : null
                                                        }
                                                    </Col>
                                                    <Col xs={4} sm={4} md={4} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                                                        <span style={{ verticalAlign: "sub" }}> Acuse</span><br />
                                                        {
                                                            cfdi.aplicaid.length ?
                                                                cfdi.aplicaid.map((item, index) => (
                                                                    item.url ? 
                                                                        <React.Fragment key={index}>
                                                                            <img src={PDF} alt="pdf" height="20" onClick={() => downloadFile(item.url, item.AplicaID, 'pdf')} />
                                                                        </React.Fragment>
                                                                    : null
                                                                ))
                                                                : <>Sin archivos</>
                                                        }
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    ))

                                : <CardBody>
                                    <Row className='mt-5'>
                                        <Col
                                            xs={12} sm={12} md={12} lg={12} xl={12}
                                            className='text-center'
                                        >
                                            <p>Aún no se cuenta con CFDIs emitidos</p>
                                        </Col>
                                    </Row>
                                </CardBody>
                        }

                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default LatestCFDIs;