import { get, post } from "../common/axios"

const getOrders = (data) => {
    return post ('/api/orders/list/', data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

const getOrdersArticles = (id) => {
    return get (`/api/orders/list?id=${id}`).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

export { getOrders, getOrdersArticles };