import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class RecentActivities extends Component {
    
    render() {
        let activity_list = [];

        this.props.activityList.filter(elem => {
            if(elem.activityList) {
                activity_list = elem.activityList;
            }
        });

        function formatDateString(date) {
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return date.toLocaleDateString('es-ES', options);
        }

        return (
            <React.Fragment>
                <Card>
                    <CardBody style={{ height: '372px', overflowX: 'auto' }}>

                        <h4 className="card-title mb-4">Actividad reciente</h4>
                        {
                            activity_list.length ? 
                                activity_list.map((item, key) =>
                                    <Row key={key}>
                                        <Col md={1}>
                                            <i className='ri-mail-line font-size-24'></i>
                                        </Col>
                                        <Col md={11} className='mb-2'>
                                            <h6 style={{ margin: 0, fontWeight: 'bold' }}>{formatDateString(new Date(item.date))}</h6>
                                            <span>{item.title}</span>
                                        </Col>
                                        <hr />
                                    </Row>
                                )
                            : <Row>
                                <Col 
                                    xs={12} sm={12} md={12} lg={12} xl={12}
                                    className='text-center'
                                >
                                    <p>Aún no hay actividades que mostrar</p>
                                </Col>
                            </Row>
                        }
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default RecentActivities;