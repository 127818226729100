import {
    GET_REFERRALS,
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAIL,
    GET_REFERRALS_ARTICLES,
    SHOW_TRACKING
} from "./actionTypes";

export const getReferrals = (data, history) => ({
    type: GET_REFERRALS,
    payload: { data, history }
});

export const getReferralsSuccess = referrals => ({
    type: GET_REFERRALS_SUCCESS,
    payload: referrals,
});

export const getReferralsFail = error => ({
    type: GET_REFERRALS_FAIL,
    payload: error
});

export const getReferralsArticles = (data, history) => ({
    type: GET_REFERRALS_ARTICLES,
    payload: { data, history }
});

export const showReferralTracking = (data, history) => ({
    type: SHOW_TRACKING,
    payload: { data, history }
});