import {
    GET_ORDERS,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_FAIL,
    GET_ORDERS_ARTICLES
} from "./actionTypes";

export const getOrders = (data, history) => ({
    type: GET_ORDERS,
    payload: { data, history },
});

export const getOrdersSuccess = orders => ({
    type: GET_ORDERS_SUCCESS,
    payload: orders,
});

export const getOrdersFail = error => ({
    type: GET_ORDERS_FAIL,
    payload: error,
});

export const getOrdersArticles = (data, history) => ({
    type: GET_ORDERS_ARTICLES,
    payload: { data, history }
});