import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import Select from "react-select";
import Dropzone, { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import { Loading, Warning, Close } from '../../common/sweetalert'

import moment from "moment";
import 'moment-timezone';

import PDF from "../../assets/images/icons/pdf.png";

import {
    Card,
    Table,
    Button,
    Col,
    CardBody,
    Container,
    Input,
    Row,
    Label,
    Tooltip,
    Form
} from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { getDocuments, uploadDocument } from "../../store/actions";

const optionGroup = [
    {
        options: [
            { id: 1, label: "Solicitud de Crédito", value: "solicitud" },
            { id: 2, label: "Acta Constitutiva", value: "acta" },
            { id: 3, label: "Poder Notarial", value: "poder" },
            { id: 4, label: "Identificación Oficial del Representante Legal", value: "idenRl" },
            { id: 5, label: "Identificación Oficial del Aval", value: "idenAval" },
            { id: 6, label: "Comprobante de Domicilio (menor a 3 meses)", value: "comprobante" },
            { id: 7, label: "Constancia de Situación Fiscal", value: "constancia" },
            { id: 8, label: "Opinión de Cumplimiento", value: "opinion" },
            { id: 9, label: "Información Financiera", value: "informacion" },
            { id: 10, label:"Reporte de Buró de Crédito", value: "reporte" },
        ]
    }
];

class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            breadcrumbItems: [
                { title: "NOVACERAMIC", link: "/" },
                { title: "Documentos", link: "#" },
            ],
            params: {
                rfc: JSON.parse(localStorage.getItem('authUser')).rfc
            },
            data: {},
            selectedFiles: [],
            file: undefined,
            width: 0,
            height: 0,
            stateInfo: props.location.state
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        // let showMobile = window.innerWidth < 768 ? true : false;
        // let showList = window.innerWidth >= 768 ? true : false;
        // this.setState({ width: window.innerWidth, height: window.innerHeight, showList: showList, showMobile: showMobile });
    }

    componentDidMount = () => {
        const { onGetDocuments } = this.props;
        onGetDocuments(this.state.params, this.props.history);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    handleAcceptedFiles = files => {

        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('jsonData', JSON.stringify(this.state.data.doc));

        this.setState({ selectedFiles: files, file: formData });
    };

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    render() {
        const { results } = this.props.docs;

        const handleDoc = event => {
            const object = {
                info: event,
                rfc: JSON.parse(localStorage.getItem('authUser')).rfc
            }
            this.setState({ data: { doc: object }})
        }

        const handleClean = () => {
            this.setState({ selectedFiles: [], data: { doc: null} })
        }

        const handleSubmit = async (event) => {
            await this.props.onUploadDocuments(this.state.file)
            this.setState({ selectedFiles: [], data: { doc: null } })
        }

        const downloadDocument = async (route, file) => {
            Loading('Descargando archivo');
            try {
                const { token } = JSON.parse(localStorage.getItem('authUser'));
                const url = `${process.env.REACT_APP_URL}/public${route}/${file}`;
                const response = await fetch(url, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                
                if (!response.ok) {
                    throw new Error(`Failed to download file: ${response.statusText}`);
                }
        
                const blob = await response.blob();
                const href = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.download = file;
        
                link.click();
                URL.revokeObjectURL(href);
                Close();
            } catch (error) {
                Warning('', 'No fue posible descargar el archivo');
            }
        };

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Documentos" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3">
                                            <Col xs={12}>
                                                <Select
                                                    options={optionGroup}
                                                    classNamePrefix="select2-selection"
                                                    placeholder="Selecciona un documento a cargar"
                                                    value={this.state.data.doc?.label}
                                                    onChange={handleDoc}
                                                    isDisabled={this.state.selectedFiles.length}
                                                >

                                                </Select>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col>
                                                <Form>
                                                    {!this.state.selectedFiles.length && this.state.data.doc ?
                                                        <Dropzone
                                                            onDrop={acceptedFiles =>
                                                                this.handleAcceptedFiles(acceptedFiles)
                                                            }
                                                            maxSize={52428800}
                                                        >
                                                            {({ getRootProps, getInputProps }) => (
                                                                <div className="dropzone cursor-pointer" style={{ 'padding': '10px'}}>
                                                                    <div
                                                                        className="dz-message needsclick"
                                                                        {...getRootProps()}
                                                                    >
                                                                        <input {...getInputProps()} />
                                                                        <div className="mb-1">
                                                                            <i className="display-5 text-muted ri-upload-cloud-2-line"></i>
                                                                        </div>
                                                                        <h4>Arrastra tu archivo aquí o has clic para cargarlo.</h4>
                                                                        <small className="text-muted">Max. 5 MB</small>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Dropzone>
                                                    : null}
                                                    <div
                                                        className="dropzone-previews mt-3"
                                                        id="file-previews"
                                                    >
                                                        {this.state.selectedFiles.map((f, i) => {
                                                            return (
                                                                <React.Fragment key={i + "-file"}>
                                                                    <Card
                                                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                                    >
                                                                        <h5 style={{ 'padding': '5px'}} className="mt-1 mb-1">Documento cargado</h5>
                                                                        <hr style={{ 'borderStyle': 'dashed', 'margin': '0' }}/>
                                                                        <div className="p-2">
                                                                            <Row className="align-items-center">
                                                                                <Col xs={12} sm={6}>
                                                                                    <div>
                                                                                        <b>Nombre del archivo: </b>{f.name}
                                                                                    </div>
                                                                                    <p className="mb-0">
                                                                                        <b>Tamaño: </b>{this.formatBytes(f.size)}
                                                                                    </p>
                                                                                </Col>
                                                                                <Col style={{ textAlign: 'right'}}>
                                                                                    <Button
                                                                                        onClick={handleClean}
                                                                                        color="secondary"
                                                                                        type="button"
                                                                                        className="waves-effect waves-light"
                                                                                        style={{marginRight: '5px'}}
                                                                                    >
                                                                                        Limpiar
                                                                                    </Button>
                                                                                    <Button
                                                                                        onClick={handleSubmit}
                                                                                        color="primary"
                                                                                        type="button"
                                                                                        className="waves-effect waves-light"
                                                                                    >
                                                                                        Cargar
                                                                                    </Button>
                                                                                    
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    </Card>
                                                                </React.Fragment>
                                                            );
                                                        })}
                                                    </div>
                                                </Form>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                {results && results.length ? 
                                                    <React.Fragment>
                                                        <Row className="mt-3">
                                                            <Col xs={12}>
                                                                <h5 className="mb-3">
                                                                    Listado de documentos cargados ({results.length})
                                                                </h5>
                                                            </Col>
                                                        </Row>
                                                        <div className="table-rep-plugin">
                                                            <div className="table-responsive mb-0">
                                                                <Table bordered striped>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>#</th>
                                                                            <th>Documento</th>
                                                                            <th>Archivo</th>
                                                                            <th>Fecha actualización</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {results.map((doc, key) => (
                                                                            <tr key={key}>
                                                                                <td>{key + 1}</td>
                                                                                <td>{doc.title}</td>
                                                                                <td>
                                                                                    <span>
                                                                                        <i 
                                                                                            className=" ri-download-cloud-2-line font-size-22 cursor-pointer" 
                                                                                            onClick={ () => downloadDocument(doc.file_route, doc.name) }
                                                                                        ></i>
                                                                                    </span>
                                                                                </td>
                                                                                <td>{moment(doc.updated_at).subtract(6, 'hours').format("YYYY-MM-DD HH:mm:ss")}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                : 
                                                    <React.Fragment>
                                                        <Row>
                                                            <Col className="text-center mt-5">
                                                                <h4>Aún no se han cargado documentos</h4>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                }
                                                
                                            </Col>
                                        </Row>
                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Documents.propTypes = {
    docs: PropTypes.any,
    onGetDocuments: PropTypes.func,
    onUploadDocuments: PropTypes.func
}

const mapStateToProps = ({ Documents }) => ({
    docs: Documents.docs
})

const mapDispatchToProps = dispatch => ({
    onGetDocuments: (data, history) => dispatch(getDocuments(data, history)),
    onUploadDocuments: (data, history) => dispatch(uploadDocument(data, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(Documents)