import { get, post } from "../common/axios"

const getInvoices = (data) => {
    return post ('/api/invoices/list/', data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

const getInvoicesArticles = (id) => {
    return get (`/api/invoices/list?id=${id}`).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

export { getInvoices, getInvoicesArticles }