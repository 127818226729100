import {
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_FAIL
} from "./actionTypes";

const INIT_STATE = {
    data: {}
}

const Dashboard = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                data: action.payload
            }
        case GET_DASHBOARD_FAIL:
            return {
                ...state,
                data: action.payload
            }
        default:
            return state;
    }
}

export default Dashboard;