import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import { loginUserService } from '../../../services/login.services';

// Login Redux States
import { CHECK_LOGIN, LOGOUT_USER } from './actionTypes';
import { apiError } from './actions';

//Initilize firebase
import { Loading, Warning, Close } from '../../../common/sweetalert'

//If user is login then dispatch redux action's are directly from here.
function* loginUser({ payload: { user, history } }) {
        Loading('Iniciando sesión')
        try {

            const newObj = {
                "Cliente": user.username,
                "Contrasena": user.password
            }

            const response = yield call(loginUserService, newObj);

            if(response.success) {
                localStorage.setItem("authUser", JSON.stringify(response.user));
                localStorage.setItem("totalAccounts", JSON.stringify(response.totalAccounts));
                history.push('/dashboard');

            Close();
            } else {
                Warning("Ocurrió un problema inesperado", response.msg)
                yield put(apiError(response.msg));
            }
            
        } catch (error) {
            Warning("Ocurrió un problema inesperado", "No fue posible acceder al servicio solicitado. Por favor contacta a tu administrador.");
            yield put(apiError(error));
        }
}

function* logoutUser({ payload: { history } }) {
    try {
        localStorage.removeItem("authUser");
        history.push('/login');
        window.location.reload();
    } catch (error) {
        yield put(apiError(error));
    }
}

export function* watchUserLogin() {
    yield takeEvery(CHECK_LOGIN, loginUser)
}

export function* watchUserLogout() {
    yield takeEvery(LOGOUT_USER, logoutUser)
}

function* loginSaga() {
    yield all([
        fork(watchUserLogin),
        fork(watchUserLogout),
    ]);
}

export default loginSaga;