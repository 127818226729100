import {
    GET_QUOTES,
    GET_QUOTES_SUCCESS,
    GET_QUOTES_FAIL,
    GET_QUOTES_ARTICLES
} from "./actionTypes";

export const getQuotes = (data, history) => ({
    type: GET_QUOTES,
    payload: { data, history }
});

export const getQuotesSuccess = quotes => ({
    type: GET_QUOTES_SUCCESS,
    payload: quotes
});

export const getQuotesFail = error => ({
    type: GET_QUOTES_FAIL,
    payload: error
});

export const showQuotesArticles = (data, history) => ({
    type: GET_QUOTES_ARTICLES,
    payload: { data, history }
});