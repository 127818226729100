import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import moment from "moment";
import 'moment-timezone';

import {
    Card,
    Table,
    Button,
    Col,
    CardBody,
    Container,
    Input,
    Row,
    Label,
    Tooltip
} from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getInvoices } from "../../store/actions";

class Invoices extends Component {
    constructor(props) {
        super(props)
        this.state = {
            showFilters: false,
            popovertop: false,
            breadcrumbItems: [
                { title: "NOVACERAMIC", link: "/" },
                { title: "Facturación", link: "#" },
            ],
            data: {
                "client": JSON.parse(localStorage.getItem('authUser')).username,
                "page": 1,
                "limit": 20,
                "filters": {
                    "invoices": "all",
                    "start_date_emission": "",
                    "end_date_emission": "",
                    "start_date_expired": "",
                    "end_date_expired": "",
                }
            },
            width: 0,
            height: 0,
            pageCount: 0,
            maxSize: 0,
            showList: true,
            showMobile: false,
            stateInfo: props.location.state
        }

        this.toggletop = this.toggletop.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    toggletop() { this.setState({ popovertop: !this.state.popovertop }); }
    toggledismiss() { this.setState({ popoverdismiss: !this.state.popoverdismiss }); }
    toggledismissclose() { this.setState({ popoverdismiss: false }); }
    updateWindowDimensions() { 
        let showMobile = window.innerWidth < 768 ? true : false;
        let showList = window.innerWidth >= 768 ? true : false;
        this.setState({ width: window.innerWidth, height: window.innerHeight, showList: showList, showMobile: showMobile });
    }

    componentDidMount = () => {
        const { onGetInvoices } = this.props;
        const { stateInfo } = this.state;
        if (stateInfo) {
            let newState;
            switch (stateInfo) {
                case 'current':
                    newState = { ...this.state.data };
                    newState.filters.invoices = 'current';
                    this.setState({ data: newState, showFilters: true });
                    break;
                case 'expired':
                    newState = { ...this.state.data };
                    newState.filters.invoices = 'expired';
                    this.setState({ data: newState, showFilters: true });
                    break;
                default:
                    break;
            }
        }
        onGetInvoices(this.state.data, this.props.history);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }


    render() {

        const style = {
            table: {
                width: '100%',
                display: 'table',
                borderSpacing: 0,
                borderCollapse: 'separate',
            },
            th: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'sticky',
            },
        };

        const { results, total, pages } = this.props.invoices;

        const setPageSize = (event) => {
            let newState = { ...this.state.data };
            newState.limit = event.target.value;
            newState.page = 1;
            this.props.onGetInvoices(newState, this.props.history);
            this.setState({ data: newState, pageCount: Math.ceil(total / event.target.value) })
        }

        const gotoPage = (page) => {
            let newState = { ...this.state.data };
            newState.page = Number(page)
            this.setState({ data: newState });
            this.props.onGetInvoices(newState, this.props.history);
        }

        const previousPage = () => {
            let newState = { ...this.state.data };
            newState.page = Number(this.state.data.page) - 1;
            this.setState({ data: newState });
            this.props.onGetInvoices(newState, this.props.history);
        }

        const nextPage = () => {
            let newState = { ...this.state.data };
            newState.page = Number(this.state.data.page) + 1;
            this.setState({ data: newState });
            this.props.onGetInvoices(newState, this.props.history);
        }

        const onChangeInInput = event => {
            const page = event.target.value ? Number(event.target.value) : 1;
            if (page > pages) page = pages;
            else if (page < 1) page = 1;
            gotoPage(page);
        };

        const handleOnChange = (event, type) => {
            let newState = { ...this.state.data };
            newState.filters[type] = event.target.value;
            this.setState({ data: newState });
        }

        const handleSubmit = event => {
            event.preventDefault();
            gotoPage(1);
            this.props.onGetInvoices(this.state.data, this.props.history);
        }

        const toogleFilters = event => {
            this.setState({ showFilters: !this.state.showFilters })
        }

        const filterData = event => {
            let newState = { ...this.state.data };
            newState.filters.invoices = event;
            this.setState({ data: newState, stateInfo: event });
            this.props.onGetInvoices(newState, this.props.history);
        }

        const cleanFilters = () => {
            let newState = { ...this.state.data };
            newState.filters = {
                "invoices": this.state.data.filters.invoices,
                "start_date_emission": "",
                "end_date_emission": "",
                "start_date_expired": "",
                "end_date_expired": "",
            }
            this.setState({ data: newState });
            this.props.onGetInvoices(newState, this.props.history);
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="Facturación" breadcrumbItems={this.state.breadcrumbItems} />

                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>
                                        {this.state.showFilters ?
                                            <>
                                                <Row className={`${this.state.width < 768 ? 'mb-3' : null}`}>
                                                    <Col xl={6} md={6} sm={6} xs={6}>
                                                        {this.state.showFilters ? <h5>Filtros</h5> : <></>}
                                                    </Col>
                                                    <Col xl={6} md={6} sm={6} xs={6}>
                                                        <div style={{ textAlign: "right" }}>
                                                            <Tooltip placement="left" isOpen={this.state.ttleft} target="TooltipFilter" toggle={() => this.setState({ ttleft: !this.state.ttleft })}>{this.state.showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}</Tooltip>
                                                            <i id="TooltipFilter" onClick={toogleFilters} className={`${!this.state.showFilters ? 'ri-filter-2-fill' : ' ri-filter-2-line'} font-size-22 cursor-pointer`}></i>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={3} md={3} sm={3}>
                                                        <Label htmlFor="example-date-input" className="col-form-label">Fecha emisión (inicio)</Label>
                                                        <Input
                                                            onChange={(e) => handleOnChange(e, 'start_date_emission')}
                                                            value={this.state.data.filters.start_date_emission}
                                                            type="date"
                                                            id="example-date-input"
                                                        />
                                                    </Col>
                                                    <Col xl={3} md={3} sm={3}>
                                                        <Label htmlFor="example-date-input" className="col-form-label">Fecha emisión (fin)</Label>
                                                        <Input
                                                            onChange={(e) => handleOnChange(e, 'end_date_emission')}
                                                            value={this.state.data.filters.end_date_emission}
                                                            type="date"
                                                            id="example-date-input"
                                                        />
                                                    </Col>
                                                    <Col xl={3} md={3} sm={3}>
                                                        <Label htmlFor="example-date-input" className="col-form-label">Fecha vencimiento (inicio)</Label>
                                                        <Input
                                                            onChange={(e) => handleOnChange(e, 'start_date_expired')}
                                                            value={this.state.data.filters.start_date_expired}
                                                            type="date"
                                                            id="example-date-input"
                                                        />
                                                    </Col>
                                                    <Col xl={3} md={3} sm={3}>
                                                        <Label htmlFor="example-date-input" className="col-form-label">Fecha vencimiento (fin)</Label>
                                                        <Input
                                                            onChange={(e) => handleOnChange(e, 'end_date_expired')}
                                                            value={this.state.data.filters.end_date_expired}
                                                            type="date"
                                                            id="example-date-input"
                                                        />
                                                    </Col>

                                                </Row>
                                                <Row className="mt-3">
                                                    <Col xl={6} md={6} sm={6} xs={12} style={{ marginTop: "auto" }}>
                                                        <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onChange={() => filterData('all')} checked={!this.state.stateInfo}/>
                                                            <label
                                                                className="btn btn-outline-info"
                                                                htmlFor="btnradio1"
                                                            >Todas</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onChange={() => filterData('expired')} checked={this.state.stateInfo === 'expired'} autoComplete="off" />
                                                            <label
                                                                className="btn btn-outline-info"
                                                                htmlFor="btnradio2"
                                                            >Vencidas</label>

                                                            <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onChange={() => filterData('current')} checked={this.state.stateInfo === 'current'} autoComplete="off" />
                                                            <label
                                                                className="btn btn-outline-info"
                                                                htmlFor="btnradio3"
                                                            >Al corriente</label>
                                                        </div>
                                                    </Col>
                                                    <Col xl={6} md={6} sm={6} xs={12} style={{ textAlign: 'right', marginTop: "auto" }}>
                                                        <Button
                                                            onClick={cleanFilters}
                                                            color="secondary"
                                                            type="button"
                                                            className="waves-effect waves-light"
                                                            style={{ marginRight: "5px" }}
                                                        >
                                                            Limpiar
                                                        </Button>
                                                        <Button
                                                            onClick={handleSubmit}
                                                            color="primary"
                                                            type="button"
                                                            className="waves-effect waves-light"
                                                        >
                                                            Buscar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </> : <></>
                                        }
                                        <Row>
                                            <Col xl={6} md={6} sm={6} xs={6}>
                                                <h5 className="mb-3">Resultados ({total})</h5>
                                            </Col>
                                            {
                                                !this.state.showFilters ?
                                                    <Col xl={6} md={6} sm={6} xs={6}>
                                                        <div style={{ textAlign: "right" }}>
                                                            <Tooltip placement="left" isOpen={this.state.ttleft} target="TooltipFilter" toggle={() => this.setState({ ttleft: !this.state.ttleft })}>{this.state.showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}</Tooltip>
                                                            <i id="TooltipFilter" onClick={toogleFilters} className={`${!this.state.showFilters ? 'ri-filter-2-fill' : ' ri-filter-2-line'} font-size-22 cursor-pointer`}></i>
                                                        </div>
                                                    </Col>
                                                    : <></>
                                            }

                                        </Row>
                                        {results && results.length ?
                                            <div className="table-rep-plugin">
                                                {this.state.showList ?
                                                    <div className="table-responsive mb-0" style={{
                                                        maxHeight: '60vh',
                                                        overflowY: 'auto',
                                                    }}>
                                                        <Table style={style.table} bordered striped>
                                                            <thead className="table-primary">
                                                                <tr>
                                                                    <th style={style.th}>#</th>
                                                                    <th style={style.th}>Movimiento</th>
                                                                    <th style={style.th}>Fecha emisión</th>
                                                                    <th style={style.th}>Fecha vencimiento</th>
                                                                    <th style={style.th}>Importe total</th>
                                                                    <th style={style.th}>Saldo</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {results.map((invoice, key) => (
                                                                    <React.Fragment key={key}>
                                                                        <tr>
                                                                            <td>{(this.state.data.page - 1) * this.state.data.limit + (key + 1)}</td>
                                                                            <td>{invoice.movement}</td>
                                                                            <td>{moment.utc(invoice.date_emission, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</td>
                                                                            <td>{moment.utc(invoice.date_expired, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</td>
                                                                            <td>{invoice.amount}</td>
                                                                            <td>{invoice.balance}</td>
                                                                        </tr>
                                                                    </React.Fragment>
                                                                ))}
                                                            </tbody>
                                                        </Table>

                                                    </div>
                                                    :
                                                    results.map((invoice, key) => (
                                                        <Card className="font-size-14" key={key}>
                                                            <CardBody style={{ padding: "7px 20px" }}>
                                                                <Row className="text-center">
                                                                    <Col xs={12} sm={12} md={12} style={{ padding: "0 2px 0 0" }}>
                                                                        <div>{invoice.movement}</div>
                                                                    </Col>
                                                                </Row>
                                                                <hr style={{ margin: "10px -20px" }} />
                                                                <Row>
                                                                    <Col xs={6} sm={6} md={6} style={{ padding: "0 2px 0 0" }}>
                                                                        <div>Emisión</div>
                                                                        <div>{moment.utc(invoice.date_emission, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy')}</div>
                                                                        <br />
                                                                        <div>Importe</div>
                                                                        <div>{invoice.amount}</div>
                                                                    </Col>

                                                                    <Col xs={6} sm={6} md={6} style={{ padding: "0 2px 0 0", textAlign: "right" }}>
                                                                        <div>Vencimiento</div>
                                                                        <div>{moment.utc(invoice.date_expired, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy')}</div>
                                                                        <br />
                                                                        <div>Saldo</div>
                                                                        <div>{invoice.balance}</div>
                                                                    </Col>
                                                                </Row>

                                                            </CardBody>
                                                        </Card>
                                                    ))

                                                }

                                                <Row className="mt-2">
                                                    <Col md={2} sm={12}>
                                                        <select
                                                            className="form-select"
                                                            value={this.state.data.limit}
                                                            onChange={setPageSize}
                                                        >
                                                            {[10, 20, 30, 40, 50, 100].map(pageSize => (
                                                                <option key={pageSize} value={pageSize}>
                                                                    Mostrar {pageSize}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg={10} md={12} sm={12} className="mt-1">
                                                        <Row className="justify-content-md-end justify-content-center align-items-center">
                                                            <Col className="col-md-auto">
                                                                <div className="d-flex gap-1">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => gotoPage(1)}
                                                                        disabled={this.state.data.page === 1}
                                                                    >
                                                                        {"<<"}
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={previousPage}
                                                                        disabled={this.state.data.page === 1}
                                                                    >
                                                                        {"<"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-md-auto d-none d-md-block">
                                                                Página{" "}
                                                                <strong>
                                                                    {this.state.data.page} de {pages}
                                                                </strong>
                                                            </Col>
                                                            <Col className="col-md-auto">
                                                                <Input
                                                                    type="number"
                                                                    min={1}
                                                                    style={{ width: 70, textAlign: 'center' }}
                                                                    max={pages}
                                                                    defaultValue={this.state.data.page}
                                                                    onChange={onChangeInInput}
                                                                />
                                                            </Col>

                                                            <Col className="col-md-auto">
                                                                <div className="d-flex gap-1">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={nextPage}
                                                                        disabled={this.state.data.page === pages}
                                                                    >
                                                                        {">"}
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => gotoPage(pages)}
                                                                        disabled={this.state.data.page === pages}
                                                                    >
                                                                        {">>"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {this.state.width < 768 ?
                                                        <Col sm={12} className="mt-2">
                                                            Página{" "}
                                                            <strong>
                                                                {this.state.data.page} de {pages}
                                                            </strong>
                                                        </Col>
                                                        : <></>}
                                                </Row>
                                            </div>
                                            :
                                            <>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                        <div className="text-center">
                                                            <h5>Sin registros</h5>
                                                            <p>Intenta con otros parámetros de búsqueda</p>
                                                            <i className="ri-search-line font-size-30"></i>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Invoices.propTypes = {
    invoices: PropTypes.any,
    onGetInvoices: PropTypes.func,
}

const mapStateToProps = ({ Invoices }) => ({
    invoices: Invoices.invoices
})

const mapDispatchToProps = dispatch => ({
    onGetInvoices: (data, history) => dispatch(getInvoices(data, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(Invoices)