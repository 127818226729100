import { takeEvery, put, call } from "redux-saga/effects";

import { Loading, Warning, Close, Question, Error } from '../../common/sweetalert'

import { GET_ORDERS, GET_ORDERS_ARTICLES } from "./actionTypes";

import { getOrdersSuccess, getOrdersFail } from "./actions";

import { getOrders, getOrdersArticles } from "../../services/orders.services";
import { refreshTkn } from "../../services/common.service"

function* onGetOrders({ payload: { data, history } }) {
    try {
        Loading('Obteniendo registros');
        const response = yield call(getOrders, data);
        yield put(getOrdersSuccess(response));
        Close();
    } catch (error) {
        if (error) {
            if (error.data && error.data.code) {
                const err_code = error.data.code;
                if (err_code === 'EXPIRED') {
                    Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                        if (res) {
                            const refresh = await refreshToken();
                            if (refresh) window.location.reload();
                        } else {
                            localStorage.removeItem("authUser");
                            history.push('/login');
                            window.location.reload();
                        }
                    })
                } else {
                    Warning('Ocurrió un problema inesperado', "No fue posible obtener las órdenes.");
                    yield put(getOrdersFail(error));
                }
            } else {
                Warning('Ocurrió un problema inesperado', "No fue posible obtener las órdenes.");
                yield put(getOrdersFail(error));
            }
        } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
        }
    }
}

function* onGetOrdersArticles({ payload: { data, history } }) {
    try {
        Loading('Obteniendo artículos');
        const response = yield call(getOrdersArticles, data.current_registry.id)

        let copy_results = { ...data };
        let { results } = copy_results

        results = results.map(elem => {
            if (elem.id === data.current_registry.id) {
                elem.articles = response.articles;
                elem.subtotal = response.subtotal;
                elem.iva = response.iva;
                elem.total = response.total;
                return elem;
            } else {
                delete elem.articles;
                return elem;
            }
        });

        const new_obj = {
            pages: data.pages,
            total: data.total,
            results: results
        }

        yield put(getOrdersSuccess(new_obj));
        Close();
    } catch (error) {
        if (error) {
            if (error.data && error.data.code) {
                const err_code = error.data.code;
                if (err_code === 'EXPIRED') {
                    Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                        if (res) {
                            const refresh = await refreshToken();
                            if (refresh) window.location.reload();
                        } else {
                            localStorage.removeItem("authUser");
                            history.push('/login');
                            window.location.reload();
                        }
                    })
                } else {
                    Warning('Ocurrió un problema inesperado', "No fue posible obtener los artículos.");
                    yield put(getOrdersFail(error));
                }
            } else {
                Warning('Ocurrió un problema inesperado', "No fue posible obtener los artículos.");
                yield put(getOrdersFail(error));
            }
        } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
        }
    }
}

async function refreshToken() {
    try {
        const response = await refreshTkn()
        if (response.success) {
            const user = JSON.parse(localStorage.getItem('authUser'));
            let user_copy = { ...user };
            let { token } = user_copy;
            token = response.token;
            user['token'] = token;
            localStorage.setItem("authUser", JSON.stringify(user));
            return true;
        }
    } catch (error) {
        return error;
    }
}

function* ordersSaga() {
    yield takeEvery(GET_ORDERS, onGetOrders)
    yield takeEvery(GET_ORDERS_ARTICLES, onGetOrdersArticles)
}

export default ordersSaga;