import React, { Component } from 'react';

import { Row, Col, Input, Button, Alert, Container, Label } from "reactstrap";

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { checkLogin, apiError } from '../../store/actions';

// import images
import logodark from "../../assets/images/logo_n_dark.png";
import logolight from "../../assets/images/logo_n_light.png";

import bg from "../../assets/images/authentication-bg.jpg"
import bg1 from "../../assets/images/authentication-bg1.jpg"
import bg2 from "../../assets/images/authentication-bg2.jpg"
import bg3 from "../../assets/images/authentication-bg3.jpg"
import bg4 from "../../assets/images/authentication-bg4.jpg"
import bg5 from "../../assets/images/authentication-bg5.jpg"
import bg6 from "../../assets/images/authentication-bg6.jpg"
// import bg3 from "../../assets/images/login1.jpg"


class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            password: "",
            checked: false,
            logo: "",
            images: [
                bg,
                bg1,
                bg2,
                bg3,
                bg4,
                bg5,
                bg6,
            ]
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, values) {
        if(this.state.checked) {
            localStorage.setItem('username', values.username);
            localStorage.setItem('password', values.password);
            localStorage.setItem('checked', this.state.checked);
        } else {
            localStorage.removeItem('username');
            localStorage.removeItem('password');
            localStorage.removeItem('checked');
        }
        this.props.checkLogin(values, this.props.history);
    }

    componentDidMount() {
        let checked = localStorage.getItem("checked");
        if (checked) {
            this.setState({ checked : true });
            this.setState({ username: localStorage.getItem('username') });
            this.setState({ password: localStorage.getItem('password') });
        }
        this.props.apiError("");
        document.body.classList.add("auth-body-bg");
        let visited = localStorage.getItem("alreadyVisited");
        if (visited) {
            this.setState({ firstTime: false })
            const randomNumber = Math.floor(Math.random() * this.state.images.length);
            const image = this.state.images[randomNumber];
            this.setState({ logo: image })

        } else {
            localStorage["alreadyVisited"] = true;
            this.setState({ firstTime: true });
            this.setState({ logo: bg })
        }
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        this.captcha();
    }

    componentWillUnmount() {
        document.body.classList.remove("auth-body-bg");
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        localStorage.removeItem("alreadyVisited");
      }

    captcha() {
        const loadScriptByURL = (id, url, callback) => {
            
            const isScriptExist = document.getElementById(id);

            if (!isScriptExist) {
                let script = document.createElement("script");
                script.type = "text/javascript";
                script.src = url;
                script.id = id;
                script.onload = function () {
                    if (callback) callback();
                };
                document.body.appendChild(script);
            }

            if (isScriptExist && callback) callback();
        }

        loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`, function () {
            console.log("Script loaded!");
        });
    }

    render() {
        const imgStyle = {
            backgroundImage: 'url(' + this.state.logo + ')',
        }

        const onHandleChecked = event => {
            this.setState({ checked: event.target.checked });
        }

        return (
            <React.Fragment>
                <div>
                    <Container fluid className="p-0">
                        <Row className="g-0">
                            <Col lg={8}>
                                <div style={imgStyle} className={`authentication-bg`}>
                                    <div className="bg-overlay"></div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                                    <div className="w-100">
                                        <Row className="justify-content-center">
                                            <Col lg={9}>
                                                <div>
                                                    <div className="text-center">
                                                        <div>
                                                            <Link to="/">
                                                                <img src={logodark} alt="" height="120" className="auth-logo logo-dark mx-auto" />
                                                                <img src={logolight} alt="" height="120" className="auth-logo logo-light mx-auto" />
                                                            </Link>
                                                        </div>

                                                        <h2 className="mt-4">Inicia sesión</h2>
                                                    </div>

                                                    {this.props.loginError && this.props.loginError ? <Alert color="danger">{this.props.loginError}</Alert> : null}

                                                    <div className="p-2 mt-3">
                                                        <AvForm className="form-horizontal" onValidSubmit={this.handleSubmit} >

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-user-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="username">Número de cuenta</Label>
                                                                <AvField 
                                                                    name="username" 
                                                                    value={this.state.username} 
                                                                    type="text" 
                                                                    className="form-control" 
                                                                    id="username" 
                                                                    placeholder="Ingresa tu número de cuenta"
                                                                    errorMessage="Campo requerido"
                                                                    validate={{ required: true }} 
                                                                />
                                                            </div>

                                                            <div className="auth-form-group-custom mb-4">
                                                                <i className="ri-lock-2-line auti-custom-input-icon"></i>
                                                                <Label htmlFor="userpassword">Contraseña</Label>
                                                                <AvField 
                                                                    name="password" 
                                                                    value={this.state.password} 
                                                                    type="password" 
                                                                    className="form-control" 
                                                                    id="userpassword" 
                                                                    placeholder="Ingresa tu contraseña"
                                                                    errorMessage="Campo requerido"
                                                                    validate={{ required: true }} 
                                                                />
                                                            </div>

                                                            <div className="form-check">
                                                                <Input 
                                                                    defaultChecked={ localStorage.getItem('checked') ? true : false }
                                                                    value={this.state.checked}
                                                                    onChange={onHandleChecked} 
                                                                    type="checkbox" 
                                                                    className="form-check-input" 
                                                                    id="customControlInline" 
                                                                />
                                                                <Label className="form-check-label" htmlFor="customControlInline">Recuérdame</Label>
                                                            </div>

                                                            <div className="mt-4 text-center">
                                                                <Button color="primary" className="w-md waves-effect waves-light" type="submit">Iniciar sesión</Button>
                                                            </div>

                                                            {/* <div className="mt-4 text-center">
                                                                <Link to="/forgot-password" className="text-muted"><i className="mdi mdi-lock me-1"></i> ¿Olvidaste tu contraseña?</Link>
                                                            </div> */}
                                                        </AvForm>
                                                    </div>

                                                    <div className="mt-5 text-center">
                                                        <span className="fw-medium text-primary cursor-pointer">Terminos y condiciones</span> | <span className="fw-medium text-primary cursor-pointer">Aviso de privacidad </span>
                                                        <p>D. R. 2023 Todos los Derechos Reservados.</p>
                                                    </div>
                                                </div>

                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

const mapStatetoProps = state => {
    const { loginError } = state.Login;
    return { loginError };
}

export default withRouter(connect(mapStatetoProps, { checkLogin, apiError })(Login));