import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import moment from "moment";
import 'moment-timezone';

import DetailView from "./DetailView";
import TrackView from "./TrackView";

import MUL0612NATU from "../../assets/images/product/MUL0612NATU.png";
import MUL1212NATU224 from "../../assets/images/product/MUL1212NATU(224).png";
import NBH2010 from "../../assets/images/product/NBH2010.png";
import NBH2010RNATU from "../../assets/images/product/NBH2010RNATU.png";
import NBH2012 from "../../assets/images/product/NBH2012.png";
import NBM2010 from "../../assets/images/product/NBM2010.png";
import NBM2010RNATU from "../../assets/images/product/NBM2010RNATU.png";
import NBM2012 from "../../assets/images/product/NBM2012.png";
import NOL092480V45 from "../../assets/images/product/NOL092480V(45).png";
import NOVDALA1520 from "../../assets/images/product/NOVDALA1520.png";
import TAB10 from "../../assets/images/product/TAB10.png";
import TAB12 from "../../assets/images/product/TAB12.png";
import TAB15 from "../../assets/images/product/TAB15.png";
import TAC10 from "../../assets/images/product/TAC10.png";
import TAC12 from "../../assets/images/product/TAC12.png";
import TAC15 from "../../assets/images/product/TAC15.png";
import VIN0612NATU from "../../assets/images/product/VIN0612NATU.png";
import VIN1212NATU224 from "../../assets/images/product/VIN1212NATU(224).png";

import "react-perfect-scrollbar/dist/css/styles.css";

import {
    Card,
    Table,
    Button,
    Col,
    CardBody,
    Container,
    Input,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    Tooltip,
    Label,
    Badge
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

import { getReferrals, getReferralsArticles, showReferralTracking } from "../../store/actions";


class Referrals extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showFilters: false,
            popovertop: false,
            breadcrumbItems: [
                { title: "NOVACERAMIC", link: "/" },
                { title: "Remisiones", link: "#" },
            ],
            data: {
                "client": JSON.parse(localStorage.getItem('authUser')).username,
                "page": 1,
                "limit": 20,
                "filters": {
                    "referrals": "all",
                    "start_date_emission": moment().subtract(6, 'months').format('YYYY-MM-DD'),
                    "end_date_emission": moment().format('YYYY-MM-DD'),
                    "start_date_required": "",
                    "end_date_required": "",
                }
            },
            width: 0,
            height: 0,
            showList: true,
            showMobile: false,
            showArticles: false,
            showTrackingView: false,
            current_registry: {},
            modal_static: false,
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.tog_static = this.tog_static.bind(this);
    }
    toggletop() { this.setState({ popovertop: !this.state.popovertop }); }
    toggledismiss() { this.setState({ popoverdismiss: !this.state.popoverdismiss }); }
    toggledismissclose() { this.setState({ popoverdismiss: false }); }
    updateWindowDimensions() { this.setState({ width: window.innerWidth, height: window.innerHeight }); }

    componentDidMount = () => {
        const { onGetReferrals } = this.props;
        onGetReferrals(this.state.data, this.props.history);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    tog_static() {
        this.setState(prevState => ({
            modal_static: !prevState.modal_static
        }));
        this.removeBodyCss();
    }

    removeBodyCss() {
        // document.body.classList.add("no_padding");
        document.body.style.overflow = 'auto';
    }

    render() {

        const style = {
            table: {
                width: '100%',
                display: 'table',
                breferralSpacing: 0,
                breferralCollapse: 'separate',
            },
            th: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'sticky',
            },
        };

        const { results, total, pages } = this.props.referrals;

        const setPageSize = (event) => {
            let newState = { ...this.state.data };
            newState.limit = event.target.value;
            newState.page = 1;
            this.props.onGetReferrals(newState, this.props.history);
            this.setState({ data: newState, pageCount: Math.ceil(total / event.target.value) })
        }

        const gotoPage = (page) => {
            let newState = { ...this.state.data };
            newState.page = Number(page)
            this.setState({ data: newState });
            this.props.onGetReferrals(newState, this.props.history);
        }

        const previousPage = () => {
            let newState = { ...this.state.data };
            newState.page = Number(this.state.data.page) - 1;
            this.setState({ data: newState });
            this.props.onGetReferrals(newState, this.props.history);
        }

        const nextPage = () => {
            let newState = { ...this.state.data };
            newState.page = Number(this.state.data.page) + 1;
            this.setState({ data: newState });
            this.props.onGetReferrals(newState, this.props.history);
        }

        const onChangeInInput = event => {
            let page = event.target.value ? Number(event.target.value) : 1;
            if (page > pages) page = pages;
            else if (page < 1) page = 1;
            gotoPage(page);
        };

        const handleOnChange = (event, type) => {
            let newState = { ...this.state.data };
            newState.filters[type] = event.target.value;
            this.setState({ data: newState });
        }

        const handleSubmit = event => {
            event.preventDefault();
            gotoPage(1);
            this.props.onGetReferrals(this.state.data, this.props.history)
        }

        const filterData = event => {
            let newState = { ...this.state.data };
            newState.filters.referrals = event;
            this.setState({ data: newState });
            this.props.onGetReferrals(newState, this.props.history);
        }

        const toogleFilters = event => {
            this.setState({ showFilters: !this.state.showFilters })
        }

        const cleanFilters = () => {
            let newState = { ...this.state.data };
            newState.filters = {
                "referrals": this.state.data.filters.referrals,
                "start_date_emission": "",
                "end_date_emission": "",
                "start_date_required": "",
                "end_date_required": "",
            }
            this.setState({ data: newState });
            this.props.onGetReferrals(newState, this.props.history);
        }

        const showTable = (index) => {
            let filter = [...results];
            filter[index].show_tracking = 0;
            let current_registry = filter[index];
            const { onGetReferralsArticles } = this.props;
            const object = {
                results: filter,
                total: total,
                pages: pages,
                current_registry: current_registry
            }
            onGetReferralsArticles(object, this.props.history);
            if (this.state.width < 768) {
                this.setState({ showList: false, showMobile: true, showArticles: true, showTrackingView: false, current_registry: current_registry });
            } else {
                this.tog_static();
            }
        }

        const showTracking = (index) => {
            let filter = [...results];
            filter[index].show_tracking = filter[index].show_tracking === 0 ? 1 : 0;
            filter[index].show_more = 0;
            let current_registry = filter[index];
            const { onShowTracking } = this.props;
            const object = {
                results: filter,
                total: total,
                pages: pages,
                current_registry: current_registry
            }
            onShowTracking(object, this.props.history);
            if (this.state.width < 768) {
                this.setState({ showList: false, showMobile: true, showArticles: false, showTrackingView: true, current_registry: current_registry });
            }
        }

        const productImages = {
            MUL0612NATU,
            MUL1212NATU224,
            NBH2010,
            NBH2010RNATU,
            NBH2012,
            NBM2010,
            NBM2010RNATU,
            NBM2012,
            NOL092480V45,
            NOVDALA1520,
            TAB10,
            TAB12,
            TAB15,
            TAC10,
            TAC12,
            TAC15,
            VIN0612NATU,
            VIN1212NATU224,
        }

        const getProduct = (img) => {
            const replaceName = img.replace(/[\(\)]/g, '');
            return productImages[replaceName];
        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        <Breadcrumbs title="Remisiones" breadcrumbItems={this.state.breadcrumbItems} />

                        {
                            this.state.showList ?
                                <Row>
                                    <Col xs={12}>
                                        <Card>
                                            <CardBody>
                                                {this.state.showFilters ?
                                                    <>
                                                        <Row className={`${this.state.width < 768 ? 'mb-3' : null}`}>
                                                            <Col xl={6} md={6} sm={6} xs={6}>
                                                                {this.state.showFilters ? <h5>Filtros</h5> : <></>}
                                                            </Col>
                                                            <Col xl={6} md={6} sm={6} xs={6}>
                                                                <div style={{ textAlign: "right" }}>
                                                                    <Tooltip placement="left" isOpen={this.state.ttleft} target="TooltipFilter" toggle={() => this.setState({ ttleft: !this.state.ttleft })}>{this.state.showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}</Tooltip>
                                                                    <i id="TooltipFilter" onClick={toogleFilters} className={`${!this.state.showFilters ? 'ri-filter-2-fill' : ' ri-filter-2-line'} font-size-22 cursor-pointer`}></i>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl={3} md={3} sm={3}>
                                                                <Label htmlFor="example-date-input" className="col-form-label">Fecha emisión (inicio)</Label>
                                                                <Input
                                                                    onChange={(e) => handleOnChange(e, 'start_date_emission')}
                                                                    value={this.state.data.filters.start_date_emission}
                                                                    type="date"
                                                                    id="example-date-input"
                                                                />
                                                            </Col>
                                                            <Col xl={3} md={3} sm={3}>
                                                                <Label htmlFor="example-date-input" className="col-form-label">Fecha emisión (fin)</Label>
                                                                <Input
                                                                    onChange={(e) => handleOnChange(e, 'end_date_emission')}
                                                                    value={this.state.data.filters.end_date_emission}
                                                                    type="date"
                                                                    id="example-date-input"
                                                                />
                                                            </Col>
                                                            <Col xl={3} md={3} sm={3}>
                                                                <Label htmlFor="example-date-input" className="col-form-label">Fecha requerida (inicio)</Label>
                                                                <Input
                                                                    onChange={(e) => handleOnChange(e, 'start_date_required')}
                                                                    value={this.state.data.filters.start_date_required}
                                                                    type="date"
                                                                    id="example-date-input"
                                                                />
                                                            </Col>
                                                            <Col xl={3} md={3} sm={3}>
                                                                <Label htmlFor="example-date-input" className="col-form-label">Fecha requerida (fin)</Label>
                                                                <Input
                                                                    onChange={(e) => handleOnChange(e, 'end_date_required')}
                                                                    value={this.state.data.filters.end_date_required}
                                                                    type="date"
                                                                    id="example-date-input"
                                                                />
                                                            </Col>

                                                        </Row>
                                                        <Row className="mt-3">
                                                            <Col xl={6} md={6} sm={6} xs={12} style={{ marginTop: "auto" }}>
                                                                <div className="btn-group" role="group" aria-label="Basic radio toggle button group">
                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onChange={() => filterData('all')} defaultChecked />
                                                                    <label
                                                                        className="btn btn-outline-info"
                                                                        htmlFor="btnradio1"
                                                                    >Todas</label>

                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio2" onChange={() => filterData('pending')} autoComplete="off" />
                                                                    <label
                                                                        className="btn btn-outline-info"
                                                                        htmlFor="btnradio2"
                                                                    >Pendientes</label>

                                                                    <input type="radio" className="btn-check" name="btnradio" id="btnradio3" onChange={() => filterData('concluded')} autoComplete="off" />
                                                                    <label
                                                                        className="btn btn-outline-info"
                                                                        htmlFor="btnradio3"
                                                                    >Entregadas</label>
                                                                </div>
                                                            </Col>
                                                            <Col xl={6} md={6} sm={6} xs={12} style={{ textAlign: 'right', marginTop: "auto" }}>
                                                                <Button
                                                                    onClick={cleanFilters}
                                                                    color="secondary"
                                                                    type="button"
                                                                    className="waves-effect waves-light"
                                                                    style={{ marginRight: "5px" }}
                                                                >
                                                                    Limpiar
                                                                </Button>
                                                                <Button
                                                                    onClick={handleSubmit}
                                                                    color="primary"
                                                                    type="button"
                                                                    className="waves-effect waves-light"
                                                                >
                                                                    Buscar
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                    </> : <></>
                                                }
                                                <Row>
                                                    <Col xl={6} md={6} sm={6} xs={6}>
                                                        <h5 className="mb-3">Resultados ({total})</h5>
                                                    </Col>
                                                    {
                                                        !this.state.showFilters ?
                                                            <Col xl={6} md={6} sm={6} xs={6}>
                                                                <div style={{ textAlign: "right" }}>
                                                                    <Tooltip placement="left" isOpen={this.state.ttleft} target="TooltipFilter" toggle={() => this.setState({ ttleft: !this.state.ttleft })}>{this.state.showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}</Tooltip>
                                                                    <i id="TooltipFilter" onClick={toogleFilters} className={`${!this.state.showFilters ? 'ri-filter-2-fill' : ' ri-filter-2-line'} font-size-22 cursor-pointer`}></i>
                                                                </div>
                                                            </Col>
                                                            : <></>
                                                    }

                                                </Row>
                                                {
                                                    results && results.length ?

                                                        <div className="table-rep-plugin">
                                                            {
                                                                this.state.width >= 768 ?
                                                                    <div className="table-responsive mb-0" style={{
                                                                        maxHeight: '60vh',
                                                                        overflowY: 'auto',
                                                                    }}>
                                                                        <Table style={style.table} bordered striped>
                                                                            <thead className="table-primary">
                                                                                <tr>
                                                                                    <th className="text-center" style={style.th}>#</th>
                                                                                    <th className="text-center" style={style.th}>Movimiento</th>
                                                                                    <th className="text-center" style={style.th}>Fecha emisión</th>
                                                                                    <th className="text-center" style={style.th}>Fecha requerida</th>
                                                                                    <th className="text-center" style={style.th}>Sucursal</th>
                                                                                    <th className="text-center" style={style.th}>Zona y ruta</th>
                                                                                    <th className="text-center" style={style.th}>Agente</th>
                                                                                    <th className="text-center" style={style.th}>Importe</th>
                                                                                    <th className="text-center" style={style.th}>Acciones</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {results.map((referral, key) => (
                                                                                    <React.Fragment key={key}>
                                                                                        <tr>
                                                                                            <td>{(this.state.data.page - 1) * this.state.data.limit + (key + 1)}</td>
                                                                                            <td>
                                                                                                <div>{referral.movement}</div>
                                                                                                <div>
                                                                                                    {
                                                                                                        referral.status === 'PENDIENTE' ?
                                                                                                            <Badge className="bg-warning rounded-pill font-size-12">{referral.status}</Badge>
                                                                                                            :
                                                                                                            <Badge className="bg-success rounded-pill font-size-12">ENTREGADA</Badge>
                                                                                                    }
                                                                                                </div>

                                                                                            </td>
                                                                                            <td>{moment.utc(referral.date_emission, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</td>
                                                                                            <td>{moment.utc(referral.date_required, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</td>
                                                                                            <td>{referral.name}<br />{referral.address}</td>
                                                                                            <td>
                                                                                                <div>
                                                                                                    <span><b>Zona: </b> {referral.zone}</span>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <span><b>Ruta: </b> {referral.route}</span>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>{referral.agent} - {referral.agent_name}</td>
                                                                                            <td>{referral.amount || 'S/D'}</td>
                                                                                            <td>
                                                                                                <div>
                                                                                                    <button style={{ width: "100%" }} className="btn btn-sm btn-primary" onClick={() => showTable(key)}>
                                                                                                        <i
                                                                                                            className={`${referral.show_more === 0 ? 'ri-menu-add-line' : 'ri-close-circle-line'} font-size-20`}
                                                                                                            style={{ verticalAlign: "sub" }}>
                                                                                                        </i>
                                                                                                        <b style={{ marginLeft: "5px" }}>{referral.show_more === 0 ? 'Artículos' : 'Ocultar'}</b>
                                                                                                    </button>
                                                                                                </div>
                                                                                                <div className="mt-2">
                                                                                                    <button style={{ width: "100%" }} className="btn btn-sm btn-info" onClick={() => showTracking(key)}>
                                                                                                        <i
                                                                                                            className={`${referral.show_tracking === 0 ? 'ri-truck-line' : 'ri-close-circle-line'} font-size-20`}
                                                                                                            style={{ verticalAlign: "sub" }}>
                                                                                                        </i>
                                                                                                        <b style={{ marginLeft: "5px" }}>{referral.show_tracking === 0 ? 'Seguimiento' : 'Ocultar'}</b>
                                                                                                    </button>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>

                                                                                        {
                                                                                            referral.show_tracking === 1 && this.state.width >= 768 ?
                                                                                                <tr>
                                                                                                    <td colSpan={9}>
                                                                                                        <Row>
                                                                                                            <Col>
                                                                                                                <div className="text-center mt-2">
                                                                                                                    <h5>Seguimiento de remisión ({
                                                                                                                        referral.tracking.finished ? 'ENTREGADO' :
                                                                                                                            referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CARGANDO' ? 'PREPARANDO' :
                                                                                                                                referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CONCLUIDO' ? 'EN PROGRESO' : 'INICIADO'

                                                                                                                    })</h5>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <Row className="mt-2">
                                                                                                            <Col>
                                                                                                                <div className="f-body">
                                                                                                                    <div className="f-containter">
                                                                                                                        <div className="steps">
                                                                                                                            <span className="circle active">
                                                                                                                                {
                                                                                                                                    !referral.tracking.data ?
                                                                                                                                        <i className="mdi mdi-truck-delivery-outline" style={{ fontSize: "35px" }}></i>
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            <span
                                                                                                                                className={`${referral.tracking.data &&
                                                                                                                                    referral.tracking.data.status &&
                                                                                                                                    (referral.tracking.data.status === 'CARGANDO' || referral.tracking.data.status === 'CONCLUIDO') ? 'active' : null} circle`}
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    referral.tracking.data && referral.tracking.data.status && (referral.tracking.data.status === 'CARGANDO' && !referral.tracking.finished) ?
                                                                                                                                        <i className="mdi mdi-truck-delivery-outline" style={{ fontSize: "35px" }}></i>
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            <span className={`${referral.tracking.data &&
                                                                                                                                referral.tracking.data.status &&
                                                                                                                                referral.tracking.data.status === 'CONCLUIDO' ? 'active' : null} circle`}
                                                                                                                            >
                                                                                                                                {
                                                                                                                                    referral.tracking.data && referral.tracking.data.status && (referral.tracking.data.status === 'CONCLUIDO' && !referral.tracking.finished) ?
                                                                                                                                        <i className="mdi mdi-truck-delivery-outline" style={{ fontSize: "35px" }}></i>
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            <span className={`${referral.tracking.finished ? 'active' : null} circle`}>
                                                                                                                                {
                                                                                                                                    referral.tracking.finished ?
                                                                                                                                        <i className="mdi mdi-truck-delivery-outline" style={{ fontSize: "35px" }}></i>
                                                                                                                                        : null
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            <div className="progress-bar">
                                                                                                                                <span className="indicator" style={{
                                                                                                                                    width: `
                                                                                                                                        ${referral.tracking.finished ? '100%' :
                                                                                                                                            referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CARGANDO' ? '33%' :
                                                                                                                                                referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CONCLUIDO' ? '66%' : '0'
                                                                                                                                        }
                                                                                                                                    `
                                                                                                                                }}>
                                                                                                                                </span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div className="titles mt-3">
                                                                                                                            <span style={{ width: "80px" }}>
                                                                                                                                Iniciado <br />
                                                                                                                                <span>{moment.utc(referral.date_emission, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                                                                                            </span>
                                                                                                                            <span style={{ width: "90px" }}>Preparando <br />
                                                                                                                                {
                                                                                                                                    referral.tracking.data && referral.tracking.data.date_entry ?
                                                                                                                                        <span>{moment.utc(referral.tracking.data.date_entry, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                                                                                                        : <></>
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            <span style={{ width: "90px" }}>En transito <br />
                                                                                                                                {
                                                                                                                                    referral.tracking.data && referral.tracking.data.date_departure ?
                                                                                                                                        <span>{moment.utc(referral.tracking.data.date_departure, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                                                                                                        : <></>
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                            <span style={{ width: "80px", textAlign: "right" }}>Entregado <br />
                                                                                                                                {
                                                                                                                                    referral.tracking.finished ?
                                                                                                                                        <span>{moment.utc(referral.tracking.finished_date, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                                                                                                        : <></>
                                                                                                                                }
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                        {
                                                                                            referral.articles && this.state.width >= 768 ?
                                                                                                <Modal
                                                                                                    size="xl"
                                                                                                    isOpen={this.state.modal_static}
                                                                                                    toggle={this.tog_static}
                                                                                                    referral={referral}
                                                                                                >
                                                                                                    <ModalHeader toggle={() => this.setState({ modal_static: false })}>
                                                                                                        {referral.movement} <small>({referral.articles.length} artículos)</small>
                                                                                                    </ModalHeader>
                                                                                                    <ModalBody className="my-modal">
                                                                                                        <Row>
                                                                                                            <Col md={12} style={{ padding: "0 15px" }}>
                                                                                                                <div className="table-rep-plugin">
                                                                                                                    <div className="table-responsive mb-0" style={{
                                                                                                                        maxHeight: '63vh',
                                                                                                                        overflowY: 'auto',
                                                                                                                    }}>
                                                                                                                        <Table height="100" style={style.table} bordered striped>
                                                                                                                            <thead className="table-primary">
                                                                                                                                <tr>
                                                                                                                                    <th className="text-center" style={style.th}></th>
                                                                                                                                    <th className="text-center" style={style.th}>Articulo</th>
                                                                                                                                    <th className="text-center" style={style.th}>Descripción</th>
                                                                                                                                    <th className="text-center" style={style.th}># Partes</th>
                                                                                                                                    <th className="text-center" style={style.th}>Cantidad</th>
                                                                                                                                    <th className="text-center" style={style.th}>Precio</th>
                                                                                                                                    <th className="text-center" style={style.th}>Pendiente</th>
                                                                                                                                    <th className="text-center" style={style.th}>Palets Pendientes</th>
                                                                                                                                    <th className="text-center" style={style.th}>Importe</th>
                                                                                                                                </tr>
                                                                                                                            </thead>
                                                                                                                            <tbody>
                                                                                                                                {referral.articles.map((art, index) => (
                                                                                                                                    <tr key={index}>
                                                                                                                                        <td className="text-center">
                                                                                                                                            {getProduct(art.article) !== undefined ?
                                                                                                                                                <img src={getProduct(art.article)} alt={art.article} height="35" className="text-center" />

                                                                                                                                                : <i className="fas fa-box font-size-22"></i>
                                                                                                                                            }
                                                                                                                                        </td>
                                                                                                                                        <td>{art.article}</td>
                                                                                                                                        <td>{art.description}</td>
                                                                                                                                        <td className="text-center">{art.part_num}</td>
                                                                                                                                        <td style={{ textAlign: "right" }}>{art.quantity.toLocaleString()}</td>
                                                                                                                                        <td style={{ textAlign: "right" }}>{art.price}</td>
                                                                                                                                        <td style={{ textAlign: "right" }}>{art.pending_quantity !== null ? art.pending_quantity.toLocaleString() : 0}</td>
                                                                                                                                        <td className="text-center">{art.pending_palets !== null ? art.pending_palets.toFixed(2) : 0}</td>
                                                                                                                                        <td style={{ textAlign: "right" }}>{art.subtotal !== null ? art.subtotal : "$0.00"}</td>
                                                                                                                                    </tr>
                                                                                                                                ))}
                                                                                                                            </tbody>
                                                                                                                        </Table>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                        <hr />
                                                                                                        <Row>
                                                                                                            <Col md={12} lg={12} xl={12} style={{ textAlign: "right", paddingRight: "15px" }}>
                                                                                                                <div>Subtotal: <b>{referral.subtotal ? referral.subtotal : "$0.00"}</b></div>
                                                                                                                <div>IVA: <b>{referral.iva ? referral.iva : "$0.00"}</b></div>
                                                                                                                <div>Total: <b>{referral.total ? referral.total : "$0.00"}</b></div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </ModalBody>
                                                                                                </Modal>
                                                                                                :
                                                                                                <></>
                                                                                        }
                                                                                    </React.Fragment>

                                                                                ))}

                                                                            </tbody>
                                                                        </Table>
                                                                    </div>

                                                                    :
                                                                    results.map((referral, key) => (
                                                                        <Card className="font-size-12" key={key}>
                                                                            <CardBody style={{ padding: "7px 20px" }}>
                                                                                <Row>
                                                                                    <Col xs={7} sm={7} md={7} style={{ padding: "0 2px 0 0" }}>
                                                                                        <div>
                                                                                            <b className="font-size-13">{referral.movement}</b>
                                                                                        </div>
                                                                                    </Col>
                                                                                    <Col xs={5} sm={5} md={5} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                                                                                        <div>
                                                                                            {
                                                                                                referral.status === 'PENDIENTE' ?
                                                                                                    <Badge className="bg-warning font-size-12">{referral.status}</Badge>
                                                                                                    :
                                                                                                    <Badge className="bg-success font-size-12">ENTREGADA</Badge>
                                                                                            }
                                                                                        </div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <hr style={{ margin: "7px -20px 8px" }} />
                                                                                <Row>
                                                                                    <Col xs={4} sm={4} md={4} style={{ padding: "0 2px 0 0" }}>
                                                                                        <div>Emisión</div>
                                                                                        <div>{moment(referral.emission_date).format('DD/MM/yyyy')}</div>
                                                                                        <br />
                                                                                        <div><b>Zona:</b> {referral.zone}</div>
                                                                                    </Col>
                                                                                    <Col xs={5} sm={5} md={5} className="text-center" style={{ padding: "0 1px" }}>
                                                                                        <div className="font-size-14">{referral.amount}</div>
                                                                                        <br />
                                                                                        <div>{referral.name}<br />{referral.address}</div>
                                                                                    </Col>
                                                                                    <Col xs={3} sm={3} md={3} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                                                                                        <div>Requerido</div>
                                                                                        <div>{moment(referral.date_required).format('DD/MM/yyyy')}</div>
                                                                                        <br />
                                                                                        <div><b>Ruta:</b> {referral.route}</div>
                                                                                    </Col>
                                                                                </Row>
                                                                                <hr style={{ margin: "10px -20px" }} />
                                                                                <Row className="mt-2">
                                                                                    <Col xs={6} sm={6} md={6} lg={6} style={{ padding: "0 2px 0 0" }}>
                                                                                        <button style={{ width: "100%" }} className="btn btn-primary btn-sm text-center" onClick={() => showTable(key)}>
                                                                                            <i className="ri-menu-add-line font-size-20" style={{ verticalAlign: "sub" }}></i> Artículos
                                                                                        </button>
                                                                                    </Col>
                                                                                    <Col xs={6} sm={6} md={6} lg={6} style={{ padding: "0 0 0 2px" }}>
                                                                                        <button style={{ width: "100%" }} className="btn btn-info btn-sm text-center" onClick={() => showTracking(key)}>
                                                                                            <i className="ri-truck-line font-size-20" style={{ verticalAlign: "sub" }}></i> Seguimiento
                                                                                        </button>
                                                                                    </Col>
                                                                                </Row>
                                                                            </CardBody>
                                                                        </Card>
                                                                    ))
                                                            }

                                                            <Row className="mt-2">
                                                                <Col md={2} sm={12}>
                                                                    <select
                                                                        className="form-select"
                                                                        value={this.state.data.limit}
                                                                        onChange={setPageSize}
                                                                    >
                                                                        {[10, 20, 30, 40, 50, 100].map(pageSize => (
                                                                            <option key={pageSize} value={pageSize}>
                                                                                Mostrar {pageSize}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </Col>
                                                                <Col lg={10} md={12} sm={12} className="mt-1">
                                                                    <Row className="justify-content-md-end justify-content-center align-items-center">
                                                                        <Col className="col-md-auto">
                                                                            <div className="d-flex gap-1">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => gotoPage(1)}
                                                                                    disabled={this.state.data.page === 1}
                                                                                >
                                                                                    {"<<"}
                                                                                </Button>
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={previousPage}
                                                                                    disabled={this.state.data.page === 1}
                                                                                >
                                                                                    {"<"}
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className="col-md-auto d-none d-md-block">
                                                                            Página{" "}
                                                                            <strong>
                                                                                {this.state.data.page} de {pages}
                                                                            </strong>
                                                                        </Col>
                                                                        <Col className="col-md-auto">
                                                                            <Input
                                                                                type="number"
                                                                                min={1}
                                                                                style={{ width: 70, textAlign: 'center' }}
                                                                                max={pages}
                                                                                defaultValue={this.state.data.page}
                                                                                onChange={onChangeInInput}
                                                                            />
                                                                        </Col>

                                                                        <Col className="col-md-auto">
                                                                            <div className="d-flex gap-1">
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={nextPage}
                                                                                    disabled={this.state.data.page === pages}
                                                                                >
                                                                                    {">"}
                                                                                </Button>
                                                                                <Button
                                                                                    color="primary"
                                                                                    onClick={() => gotoPage(pages)}
                                                                                    disabled={this.state.data.page === pages}
                                                                                >
                                                                                    {">>"}
                                                                                </Button>
                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Col>
                                                                {this.state.width < 768 ?
                                                                    <Col sm={12} className="mt-2">
                                                                        Página{" "}
                                                                        <strong>
                                                                            {this.state.data.page} de {pages}
                                                                        </strong>
                                                                    </Col>
                                                                    : <></>}
                                                            </Row>
                                                        </div>
                                                        :
                                                        <Row>
                                                            <Col xl={12} lg={12} md={12} sm={12}>
                                                                <div className="text-center">
                                                                    <h5>Sin registros</h5>
                                                                    <p>Intenta con otros parámetros de búsqueda</p>
                                                                    <i className="ri-search-line font-size-30"></i>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                }
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                                :
                                <></>}
                        {
                            this.state.showMobile && this.state.current_registry.articles && this.state.showArticles ?
                                <DetailView
                                    data={this.state.current_registry}
                                    onChangeView={() => this.setState({ showList: true, showMobile: false, showArticles: false, current_registry: {} })}
                                />
                                :
                                <></>
                        }

                        {
                            this.state.showMobile && (this.state.current_registry && this.state.current_registry.tracking) && this.state.showTrackingView ?
                                <TrackView
                                    referral={this.state.current_registry}
                                    onChangeView={() => this.setState({ showList: true, showMobile: false, showTrackingView: false, current_registry: {} })}
                                />
                                :
                                <></>
                        }

                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

Referrals.propTypes = {
    referrals: PropTypes.any,
    onGetReferrals: PropTypes.func,
    onGetReferralsArticles: PropTypes.func,
    onShowTracking: PropTypes.func
}

const mapStateToProps = ({ Referrals }) => ({
    referrals: Referrals.referrals
});

const mapDispatchToProps = dispatch => ({
    onGetReferrals: (data, history) => dispatch(getReferrals(data, history)),
    onGetReferralsArticles: (data, history) => dispatch(getReferralsArticles(data, history)),
    onShowTracking: (data, history) => dispatch(showReferralTracking(data, history))
});

export default connect(mapStateToProps, mapDispatchToProps)(Referrals);