import { takeEvery, put, call } from "redux-saga/effects"

import { Loading, Warning, Close, Question, Error } from '../../common/sweetalert'

import { GET_DOCUMENTS, UPLOAD_DOCUMENTS } from "./actionTypes"
import { getDocumentsSuccess, getDocumentsFail, uploadDocumentSuccess, uploadDocumentFail} from "./actions"

import { getDocuments, uploadDocuments } from "../../services/documents.services"
import { refreshTkn } from "../../services/common.service"

function* onGetDocuments({ payload: { params, history } }) {
    try {
        Loading('Obteniendo documentos');
        const response = yield call(getDocuments, params);
        yield put(getDocumentsSuccess(response));
        Close();
    } catch (error) {
        if(error) {
            if (error.data && error.data.code) {
              const err_code = error.data.code;
              if (err_code === 'EXPIRED') {
                Question('Sesión finalizada','Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                  if(res) {
                    const refresh = await refreshToken();
                    if(refresh) window.location.reload();
                  } else {
                    localStorage.removeItem("authUser");
                    history.push('/login');
                    window.location.reload();
                  }
                })
              } else {
                Warning('Ocurrió un problema inesperado', "No fue posible obtener los documentos");
                yield put(getDocumentsFail(error));
              }
            } else {
              Warning('Ocurrió un problema inesperado', "No fue posible obtener los documentos");
              yield put(getDocumentsFail(error));
            }
          } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
          }
    }
}

function* onUploadDocuments({ payload: { data, history } }) {
    try {
        Loading('Cargando documento');
        const response = yield call(uploadDocuments, data)
        yield put(uploadDocumentSuccess(response))
        const params = JSON.parse(data.get('jsonData'));
        const payload = {
          params: {rfc: params.rfc}, 
          history
        }
        yield call(onGetDocuments, {payload: payload})
        Close();
    } catch (error) {
        if(error) {
            if (error.data && error.data.code) {
              const err_code = error.data.code;
              if (err_code === 'EXPIRED') {
                Question('Sesión finalizada','Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                  if(res) {
                    const refresh = await refreshToken();
                    if(refresh) window.location.reload();
                  } else {
                    localStorage.removeItem("authUser");
                    history.push('/login');
                    window.location.reload();
                  }
                })
              } else {
                Warning('Ocurrió un problema inesperado', "No fue posible cargar el documento");
                yield put(uploadDocumentFail(error));
              }
            } else {
              Warning('Ocurrió un problema inesperado', "No fue posible cargar el documento");
              yield put(uploadDocumentFail(error));
            }
          } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
          }
    }
}

async function refreshToken() {
    try {
      const response = await refreshTkn()
      if(response.success) {
        const user = JSON.parse(localStorage.getItem('authUser'));
        let user_copy = { ...user };
        let { token } = user_copy;
        token = response.token;
        user['token'] = token;
        localStorage.setItem("authUser", JSON.stringify(user)); 
        return true;
      }
    } catch (error) {
      return error;
    }
}

function* documentsSaga() {
    yield takeEvery(GET_DOCUMENTS, onGetDocuments);
    yield takeEvery(UPLOAD_DOCUMENTS, onUploadDocuments);
}

export default documentsSaga;