import React, { Component } from 'react';
import { Row, Col, Card, CardBody, ButtonGroup, Button } from 'reactstrap';

//Import Charts
import { RevenueAnalyticsChart } from './Charts'
import "./dashboard.scss";

class InvoicesHistory extends Component {

    render() {

        let invoice_list = [];

        this.props.invoicesList.filter(elem => {
            if(elem.invoicesList) {
                invoice_list = elem.invoicesList;
            }
        });

        return (
            <React.Fragment>
                <Card>
                    <CardBody style={{ height: "455px"}}>
                        <h4 className="card-title mb-2">Historial de Facturas pendientes por pagar</h4>
                        {
                            invoice_list.length ? 
                                <div id="line-column-chart" className="apex-charts" dir="ltr">
                                    <RevenueAnalyticsChart invoice_list={invoice_list} history={this.props.history}/>
                                </div>  
                            : <Row className='mt-5'>
                                <Col 
                                    xs={12} sm={12} md={12} lg={12} xl={12}
                                    className='text-center'
                                >
                                    <p>Aún no se cuenta con un historial de facturas pendientes por pagar</p>
                                </Col>
                            </Row>
                        }
                        
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default InvoicesHistory;