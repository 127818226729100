import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Dashboard
import Dashboard from "../pages/Dashboard/index";

// Invoices
import Invoices from "../pages/Invoices/Invoices";

// CFDI
import CFDI from "../pages/CFDI/CFDI";

// Quotes
import Quotes from "../pages/Quotes/Quotes";

// Orders
import Orders from "../pages/Orders/Orders";

// Referrals
import Referrals from "../pages/Referrals/Referrals";

// Documents
import Documents from "../pages/Documents/Documents";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";


const authProtectedRoutes = [

	// Dashboard
	{ path: "/dashboard", component: Dashboard },
	
	// CFDI
	{ path: "/cfdi", component: CFDI },
	
	// Invoices
	{ path: "/invoices", component: Invoices },

	// Quotes
	{ path: "/quotes", component: Quotes },

	// Orders
	{ path: "/orders", component: Orders },

	// Orders
	{ path: "/referrals", component: Referrals },

	// Documents
	{ path: "/documents", component: Documents },

	// this route should be at the end of all other routes
	{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }
];

const publicRoutes = [
	{ path: "/logout", component: Logout },
	{ path: "/login", component: Login },
	{ path: "/forgot-password", component: ForgetPwd },

	// Authentication Inner
	{ path: "/auth-login", component: Login1 },
	{ path: "/auth-register", component: Register1 },
	{ path: "/auth-recoverpw", component: ForgetPwd1 },
];

export { authProtectedRoutes, publicRoutes };
