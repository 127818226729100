import { login } from '../common/axios'

const loginUserService = (data) => {
    return login(data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        var message;
        if (err.response && err.response.status) {
            switch (err.response.status) {
                case 404: message = "La página solicitada no existe."; break;
                case 500: message = "Algo salió mal. Porfavor contacta al personal de soporte."; break;
                default: message = err[1]; break;
            }
        }
        throw message;
    });
}

export { loginUserService }