import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

//i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from '../../../assets/images/users/profile.png';

class ProfileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menu: false,
            width: 0,
            height: 0,
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.toggle = this.toggle.bind(this);
    }

    updateWindowDimensions() { this.setState({ width: window.innerWidth, height: window.innerHeight }); }

    toggle() {
        this.setState(prevState => ({
            menu: !prevState.menu
        }));
    }

    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {

        const style = {
            width: "190px",
            height: "35px",
            overflow: "hidden",
            display: "inline-flex",
            fontSize: "12px",
            verticalAlign: "super",
            lineHeight: "12px"
        }

        let username = "Admin";
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            username = obj.name;
        }

        return (
            <React.Fragment>
                <Dropdown isOpen={this.state.menu} toggle={this.toggle} className="d-inline-block user-dropdown">
                    <DropdownToggle tag="button" className="btn header-item waves-effect" id="page-header-user-dropdown">
                        { this.state.width < 768 ? <span style={style} className="ms-1 text-transform">{username}</span> : <></> }
                        <img className="rounded-circle header-profile-user me-1" style={ this.state.width < 768 ? { verticalAlign: "bottom" } : null } src={avatar2} alt="Header Avatar" />
                        <span className="d-none d-xl-inline-block ms-1 text-transform">{username}</span>
                        <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem className="text-danger" href="/#/logout"><i className="ri-shut-down-line align-middle me-1 text-danger"></i> {this.props.t('Cerrar sesión')}</DropdownItem>
                    </DropdownMenu>
                </Dropdown>
            </React.Fragment>
        );
    }
}

export default withNamespaces()(ProfileMenu);
