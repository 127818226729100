import { post } from "../common/axios";

const getCFDIs = (data) => {
    return post('/api/cfdi/list/', data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

export { getCFDIs }