import {
    GET_DOCUMENTS,
    GET_DOCUMENTS_SUCCESS,
    GET_DOCUMENTS_FAILS,
    UPLOAD_DOCUMENTS,
    UPLOAD_DOCUMENTS_SUCCESS,
    UPLOAD_DOCUMENTS_FAILS
} from "./actionTypes"

export const getDocuments = (params, history) => ({
    type: GET_DOCUMENTS,
    payload: { params, history }
})

export const getDocumentsSuccess = docs => ({
    type: GET_DOCUMENTS_SUCCESS,
    payload: docs
})

export const getDocumentsFail = error => ({
    type: GET_DOCUMENTS_FAILS,
    payload: error
})

export const uploadDocument = (data, history) => ({
    type: UPLOAD_DOCUMENTS,
    payload: { data, history }
})

export const uploadDocumentSuccess = result => ({
    type: UPLOAD_DOCUMENTS_SUCCESS,
    payload: result
})

export const uploadDocumentFail = error => ({
    type: UPLOAD_DOCUMENTS_FAILS,
    payload: error
})