import { takeEvery, put, call } from "redux-saga/effects"

import { Loading, Warning, Close, Question, Error } from '../../common/sweetalert'

import { GET_CFDIS } from "./actionTypes"
import { getCFDIsSuccess, getCFDIsFail } from "./actions"

import { getCFDIs } from "../../services/cfdi.services"
import { refreshTkn } from "../../services/common.service"

function* onGetCFDIs({ payload: { data, history } }) {
  try {
    Loading('Obteniendo registros')
    const response = yield call(getCFDIs, data);
    yield put(getCFDIsSuccess(response));
    Close();
  } catch (error) {
    if(error) {
      if (error.data && error.data.code) {
        const err_code = error.data.code;
        if (err_code === 'EXPIRED') {
          Question('Sesión finalizada','Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
            if(res) {
              const refresh = await refreshToken();
              if(refresh) window.location.reload();
            } else {
              localStorage.removeItem("authUser");
              history.push('/login');
              window.location.reload();
            }
          })
        } else {
          Warning('Ocurrió un problema inesperado', "No fue posible obtener los datos de los CFDI's");
          yield put(getCFDIsFail(error));
        }
      } else {
        Warning('Ocurrió un problema inesperado', "No fue posible obtener los datos de los CFDI's");
        yield put(getCFDIsFail(error));
      }
    } else {
      Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
    }
  }
}

async function refreshToken() {
  try {
    const response = await refreshTkn()
    if(response.success) {
      const user = JSON.parse(localStorage.getItem('authUser'));
      let user_copy = { ...user };
      let { token } = user_copy;
      token = response.token;
      user['token'] = token;
      localStorage.setItem("authUser", JSON.stringify(user)); 
      return true;
    }
  } catch (error) {
    return error;
  }
}

function* cfdiSaga() {
  yield takeEvery(GET_CFDIS, onGetCFDIs);
}

export default cfdiSaga;
