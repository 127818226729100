import { takeEvery, put, call } from "redux-saga/effects";

import { Loading, Warning, Close, Question, Error } from '../../common/sweetalert';

import { GET_DASHBOARD_DATA } from "./actionTypes";
import { getDashboardDataSuccess, getDashboardDataFail } from "./actions";
import { getDashboardDataService } from "../../services/dashboard.services";

import { refreshTkn } from "../../services/common.service"

function* onGetDashboardData({ payload: { params, history } }) {
    try {
        Loading('Obteniendo datos');
        const response = yield call(getDashboardDataService, params);
        yield put(getDashboardDataSuccess(response));
        Close();
    } catch (error) {
        if (error) {
            if (error.data && error.data.code) {
                const err_code = error.data.code;
                if (err_code === 'EXPIRED') {
                    Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                        if (res) {
                            const refresh = await refreshToken();
                            if(refresh) window.location.reload();
                        } else {
                            localStorage.removeItem("authUser");
                            history.push('/login');
                            window.location.reload();
                        }
                    })
                } else {
                    Warning('Ocurrió un problema inesperado', "No fue posible obtener los datos del resumen.");
                    yield put(getDashboardDataFail(error));
                }
            } else {
                Warning('Ocurrió un problema inesperado', "No fue posible obtener los datos del resumen.");
                yield put(getDashboardDataFail(error));
            }
        } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
        }

    }
}

async function refreshToken() {
    try {
      const response = await refreshTkn()
      if(response.success) {
        const user = JSON.parse(localStorage.getItem('authUser'));
        let user_copy = { ...user };
        let { token } = user_copy;
        token = response.token;
        user['token'] = token;
        localStorage.setItem("authUser", JSON.stringify(user)); 
        return true;
      }
    } catch (error) {
      return error;
    }
  }

function* dashboardSaga() {
    yield takeEvery(GET_DASHBOARD_DATA, onGetDashboardData);
}

export default dashboardSaga;
