import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Loading, Warning, Close } from '../../common/sweetalert'
import Select from "react-select";

import './CFDI.scss';

import moment from "moment";
import 'moment-timezone';

import {
    Card,
    Table,
    Button,
    Col,
    CardBody,
    Container,
    Input,
    Row,
    Label,
    Tooltip
} from "reactstrap";

import "react-perfect-scrollbar/dist/css/styles.css";

import PDF from "../../assets/images/icons/pdf.png";
import XML from "../../assets/images/icons/xml1.png";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

import { getCFDIs } from "../../store/actions";

const optionGroup = [
    {
        options: [
            { label: "Factura", value: "Factura" },
            { label: "Complemento de Pago", value: "Cobro" },
            { label: "Aplicación de Anticipo", value: "Aplicacion" },
            { label: "Factura Anticipo", value: "FAnticipo" },
            { label: "Nota de Crédito", value: "Nota Credito" },
        ]
    }
];

class CFDI extends Component {
    constructor(props) {
        super(props);

        const today = new Date();
        const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

        this.state = {
            showFilters: true,
            popovertop: false,
            data: {
                "sort_field": "FechaEmision",
                "ordered": "DESC",
                "page": 1,
                "limit": 20,
                "filters": {
                    "current": "",
                    "account": "",
                    "serie": "",
                    "start_date": moment(firstDay).format('YYYY-MM-DD'),
                    "end_date": moment(lastDay).format('YYYY-MM-DD'),
                    "movements": null,
                    "client": JSON.parse(localStorage.getItem('totalAccounts')) > 1 ?
                        { "rfc": JSON.parse(localStorage.getItem('authUser')).rfc, "cte": "" } :
                        { "cte": JSON.parse(localStorage.getItem('authUser')).username, "rfc": "" }
                }
            },
            sort_icon: "",
            sort_order: 'DESC',
            pageCount: 0,
            maxSize: 0,
            breadcrumbItems: [
                { title: "NOVACERAMIC", link: "/" },
                { title: "CFDI", link: "#" },
            ],
            width: 0,
            height: 0,
            showList: true,
            showMobile: false,
            
        };
        this.toggletop = this.toggletop.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    toggletop() { this.setState({ popovertop: !this.state.popovertop }); }
    toggledismiss() { this.setState({ popoverdismiss: !this.state.popoverdismiss }); }
    toggledismissclose() { this.setState({ popoverdismiss: false }); }
    updateWindowDimensions() {
        let showMobile = window.innerWidth < 768 ? true : false;
        let showList = window.innerWidth >= 768 ? true : false;
        this.setState({ width: window.innerWidth, height: window.innerHeight, showList: showList, showMobile: showMobile });
    }

    componentDidMount() {
        const { onGetCFDIs } = this.props;
        onGetCFDIs(this.state.data, this.props.history);
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {

        const style = {
            table: {
                width: '100%',
                display: 'table',
                borderSpacing: 0,
                borderCollapse: 'separate',
            },
            th: {
                top: 0,
                left: 0,
                zIndex: 2,
                position: 'sticky',
            },
        };

        const { results, total, pages } = this.props.cfdis;

        const sortFields = (field) => {
            this.state.data.sort_field = field
            // this.props.onGetCFDIs(this, this.props.history.state.data)
        }

        const handleMultiSelector = item => {
            let newState = { ...this.state.data };
            let { filters } = newState;
            filters.movements = item;
            newState.filters = filters;
            this.setState({ data: newState });
        };

        const setPageSize = (event) => {
            let newState = { ...this.state.data };
            newState.limit = event.target.value;
            newState.page = 1;
            this.props.onGetCFDIs(newState, this.props.history);
            this.setState({ data: newState, pageCount: Math.ceil(total / event.target.value) })
        }

        const gotoPage = (page) => {
            let newState = { ...this.state.data };
            newState.page = Number(page)
            this.setState({ data: newState });
            this.props.onGetCFDIs(newState, this.props.history);
        }

        const previousPage = () => {
            let newState = { ...this.state.data };
            newState.page = Number(this.state.data.page) - 1;
            this.setState({ data: newState });
            this.props.onGetCFDIs(newState, this.props.history);
        }

        const nextPage = () => {
            let newState = { ...this.state.data };
            newState.page = Number(this.state.data.page) + 1;
            this.setState({ data: newState });
            this.props.onGetCFDIs(newState, this.props.history);
        }

        const onChangeInInput = event => {
            let page = event.target.value ? Number(event.target.value) : 1;
            if (page > pages) page = pages;
            else if (page < 1) page = 1;
            gotoPage(page);
        };

        const handleOnChange = (event, type) => {
            let newState = { ...this.state.data };
            newState.filters[type] = event.target.value;
            this.setState({ data: newState });
        }

        const handleSubmit = event => {
            event.preventDefault();
            gotoPage(1);
            this.props.onGetCFDIs(this.state.data, this.props.history)
        }

        const toogleFilters = event => {
            this.setState({ showFilters: !this.state.showFilters })
        }

        const cleanFilters = () => {
            let newState = { ...this.state.data };
            let { client } = newState.filters;
            newState.filters = {
                "start_date": "",
                "end_date": "",
                "account": "",
                "serie": "",
                "current": "",
                "client": client,
                "movements" : null
            }
            this.setState({ data: newState });
            this.props.onGetCFDIs(newState, this.props.history);
        }

        const downloadFile = async (path, id, ext) => {
            Loading('Descargando archivo')
            try {
                const url = `https://clientes.tabimax.com.mx/private/download?file=${path}&id=${id}.${ext}`;
                const response = await fetch(url);
                if (!response.ok) {
                    return Warning('Archivo inexistente', 'Error al descargar el archivo');
                }

                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `${id}.${ext}`;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                Close();
            } catch (error) {
                Warning('', 'No fue posible descargar el archivo')
            }

        }

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>
                        <Breadcrumbs title="CFDI (Comprobante Físcal Dígital por Internet)" breadcrumbItems={this.state.breadcrumbItems} />
                        <Row>
                            <Col xs={12}>
                                <Card>
                                    <CardBody>

                                        {this.state.showFilters ?
                                            <>
                                                <Row>
                                                    <Col xl={6} md={6} sm={6}>
                                                        {this.state.showFilters ? <h5>Filtros</h5> : <></>}
                                                    </Col>
                                                    <Col xl={6} md={6} sm={6}>
                                                        <div style={{ textAlign: "right" }}>
                                                            <Tooltip placement="left" isOpen={this.state.ttleft} target="TooltipFilter" toggle={() => this.setState({ ttleft: !this.state.ttleft })}>{this.state.showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}</Tooltip>
                                                            <i id="TooltipFilter" onClick={toogleFilters} className={`${!this.state.showFilters ? 'ri-filter-2-fill' : ' ri-filter-2-line'} font-size-22 cursor-pointer`}></i>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xl={3} md={3} sm={2}>
                                                        <Label htmlFor="example-date-input" className="col-form-label">Fecha inicio emisión</Label>
                                                        <Input
                                                            onChange={(e) => handleOnChange(e, 'start_date')}
                                                            value={this.state.data.filters.start_date}
                                                            type="date"
                                                            id="example-date-input"
                                                        />
                                                    </Col>
                                                    <Col xl={3} md={3} sm={2}>
                                                        <Label htmlFor="example-date-input" className="col-form-label">Fecha fin emisión</Label>
                                                        <Input
                                                            onChange={(e) => handleOnChange(e, 'end_date')}
                                                            value={this.state.data.filters.end_date}
                                                            type="date"
                                                            id="example-date-input"
                                                        />
                                                    </Col>
                                                    <Col xl={6} md={6} sm={6} style={this.state.showMobile ? { marginBottom: "10px" } : null}>
                                                        <Label htmlFor="example-date-input" className="col-form-label">Movimiento</Label>
                                                        <Select
                                                            value={this.state.data.filters.movements}
                                                            isMulti={true}
                                                            onChange={handleMultiSelector}
                                                            options={optionGroup}
                                                            classNamePrefix="select2-selection"
                                                            placeholder="Selecciona las opciones a filtrar"
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row style={{ marginTop: "10px" }}>
                                                    <Col xl={12} md={12} sm={12} style={{ textAlign: 'right', marginTop: "auto" }}>
                                                        <Button
                                                            onClick={cleanFilters}
                                                            color="secondary"
                                                            type="button"
                                                            className="waves-effect waves-light"
                                                            style={{ marginRight: "5px" }}
                                                        >
                                                            Limpiar
                                                        </Button>
                                                        <Button
                                                            onClick={handleSubmit}
                                                            color="primary"
                                                            type="button"
                                                            className="waves-effect waves-light"
                                                        >
                                                            Buscar
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <hr />
                                            </> : <></>
                                        }

                                        <Row>
                                            <Col xl={6} md={6} sm={6}>
                                                <h5 className="mb-3">Resultados ({total})</h5>
                                            </Col>
                                            {
                                                !this.state.showFilters ?
                                                    <Col xl={6} md={6} sm={6}>
                                                        <div style={{ textAlign: "right" }}>
                                                            <Tooltip placement="left" isOpen={this.state.ttleft} target="TooltipFilter" toggle={() => this.setState({ ttleft: !this.state.ttleft })}>{this.state.showFilters ? 'Ocultar filtros' : 'Mostrar filtros'}</Tooltip>
                                                            <i id="TooltipFilter" onClick={toogleFilters} className={`${!this.state.showFilters ? 'ri-filter-2-fill' : ' ri-filter-2-line'} font-size-22 cursor-pointer`}></i>
                                                        </div>
                                                    </Col>
                                                    : <></>
                                            }

                                        </Row>
                                        {results && results.length ?
                                            <div className="table-rep-plugin">
                                                {
                                                    this.state.showList ?
                                                        <div className="table-responsive mb-0" style={{
                                                            maxHeight: !this.state.showFilters ? '60vh' : '52vh',
                                                            overflowY: 'auto',
                                                        }}>
                                                            <Table style={style.table} bordered striped>
                                                                <thead className="table-primary">
                                                                    <tr>
                                                                        <th style={style.th} >#</th>
                                                                        <th style={style.th} >Tipo</th>
                                                                        <th style={style.th} >Movimiento</th>
                                                                        <th style={style.th} >Cuenta</th>
                                                                        <th style={style.th} >Emisión</th>
                                                                        <th style={style.th} >Vencimiento</th>
                                                                        <th style={style.th} >Importe</th>
                                                                        <th style={style.th} className='text-center'>CFDI</th>
                                                                        <th style={style.th} className='text-center'>Acuse</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {results.map((cte, key) => (
                                                                        <tr
                                                                            key={key}
                                                                        >
                                                                            <td>{(this.state.data.page - 1) * this.state.data.limit + (key + 1)}</td>
                                                                            <td>{cte.movement || 'S/D'}</td>
                                                                            <td>{cte.movId || 'S/D'}</td>
                                                                            <td>{cte.client || 'S/D'}</td>
                                                                            <td>{moment.utc(cte.emission_date, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</td>
                                                                            <td>{moment.utc(cte.expiration_date, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</td>
                                                                            <td>{cte.amount || 'S/D'}</td>
                                                                            <td className='text-center'>
                                                                                {
                                                                                    cte.pdf ? 
                                                                                        <span style={{ marginRight: 10 }} className="cursor-pointer">
                                                                                            <Tooltip placement="top" isOpen={this.state.tttop} target="TooltipTopCfdiPdf" toggle={() => this.setState({ tttop: !this.state.tttop })}>Descargar PDF</Tooltip>
                                                                                            <img id="TooltipTopCfdiPdf" src={PDF} alt="pdf" height="30" onClick={() => downloadFile(cte.pdf, cte.mov, 'pdf')} />
                                                                                        </span>
                                                                                    : null
                                                                                }
                                                                                {
                                                                                    cte.xml ? 
                                                                                        <span className="cursor-pointer">
                                                                                            <Tooltip placement="top" isOpen={this.state.tttop1} target="TooltipTopCfdiXml" toggle={() => this.setState({ tttop1: !this.state.tttop1 })}>Descargar XML</Tooltip>
                                                                                            <img id="TooltipTopCfdiXml" src={XML} alt="xml" height="30" onClick={() => downloadFile(cte.xml, cte.mov, 'xml')} />
                                                                                        </span>
                                                                                    : null
                                                                                }
                                                                            </td>
                                                                            <td className='text-center'>
                                                                                {
                                                                                    cte.aplicaid.length ?
                                                                                        cte.aplicaid.map((item, index) => (
                                                                                            item.url ?
                                                                                                <React.Fragment key={index}>
                                                                                                    <Tooltip
                                                                                                        placement="top"
                                                                                                        isOpen={this.state[`tttop${index + 2}`]}
                                                                                                        target={`TooltipTopCfdiPdf${index + 2}`}
                                                                                                        toggle={() => this.setState({ [`tttop${index + 2}`]: !this.state[`tttop${index + 2}`] })}
                                                                                                    >
                                                                                                        Descargar PDF
                                                                                                    </Tooltip>
                                                                                                    <img
                                                                                                        id={`TooltipTopCfdiPdf${index + 2}`}
                                                                                                        src={PDF}
                                                                                                        alt="pdf"
                                                                                                        height="30"
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        onClick={() => downloadFile(item.url, item.AplicaID, 'pdf')}
                                                                                                    />
                                                                                                </React.Fragment>
                                                                                            : null
                                                                                        ))
                                                                                        : <>Sin archivos</>
                                                                                }

                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </Table>
                                                        </div>
                                                        :
                                                        results.map((cfdi, key) => (
                                                            <Card className="font-size-12" key={key}>
                                                                <CardBody style={{ padding: "7px 20px" }}>
                                                                    <Row>
                                                                        <Col xs={12} sm={12} md={12} className="text-center">
                                                                            <b className="font-size-13">{cfdi.movement}</b>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr style={{ margin: "5px -20px" }} />
                                                                    <Row>
                                                                        <Col xs={4} sm={4} md={4} style={{ padding: "0 2px 0 0" }}>
                                                                            <div>Emisión</div>
                                                                            <div>{moment(cfdi.emission_date).format('DD/MM/yyyy')}</div>
                                                                        </Col>
                                                                        <Col xs={5} sm={5} md={5} className="text-center" style={{ padding: "0 1px" }}>
                                                                            <div className="font-size-14">{cfdi.amount}</div>
                                                                            <br />
                                                                            <div>{cfdi.movId}</div>
                                                                            <div><b>Cuenta: </b>{cfdi.client}</div>
                                                                        </Col>
                                                                        <Col xs={3} sm={3} md={3} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                                                                            <div>Vencimiento</div>
                                                                            <div>{moment(cfdi.expiration_date).format('DD/MM/yyyy')}</div>
                                                                        </Col>
                                                                    </Row>
                                                                    <hr style={{ margin: "5px -20px 10px" }} />
                                                                    <Row>
                                                                        <Col xs={4} sm={4} md={4} style={{ padding: "0 2px 0 0" }}>
                                                                            <span style={{ verticalAlign: "sub" }}> Factura</span><br />
                                                                            {
                                                                                cfdi.pdf ?
                                                                                    <img src={PDF} alt="pdf" height="20" onClick={() => downloadFile(cfdi.pdf, cfdi.mov, 'pdf')} />
                                                                                : null
                                                                            }
                                                                        </Col>
                                                                        <Col xs={4} sm={4} md={4} className="text-center" style={{ padding: "0 1px" }}>
                                                                            <span style={{ verticalAlign: "sub" }}> XML</span><br />
                                                                            {
                                                                                cfdi.xml ?
                                                                                    <img src={XML} alt="xml" height="20" onClick={() => downloadFile(cfdi.xml, cfdi.mov, 'xml')} />
                                                                                : null
                                                                            }
                                                                        </Col>
                                                                        <Col xs={4} sm={4} md={4} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                                                                            <span style={{ verticalAlign: "sub" }}> Acuse</span><br />
                                                                            {
                                                                                cfdi.aplicaid.length ?
                                                                                    cfdi.aplicaid.map((item, index) => (
                                                                                        <React.Fragment key={index}>
                                                                                            {
                                                                                                item.url ?
                                                                                                    <img src={PDF} alt="pdf" height="20" onClick={() => downloadFile(item.url, item.AplicaID, 'pdf')} />
                                                                                                : null
                                                                                            }
                                                                                        </React.Fragment>
                                                                                    ))
                                                                                    : <>Sin archivos</>
                                                                            }
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        ))
                                                }
                                                <Row className="mt-2">
                                                    <Col md={2} sm={12}>
                                                        <select
                                                            className="form-select"
                                                            value={this.state.data.limit}
                                                            onChange={setPageSize}
                                                        >
                                                            {[10, 20, 30, 40, 50, 100].map(pageSize => (
                                                                <option key={pageSize} value={pageSize}>
                                                                    Mostrar {pageSize}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </Col>
                                                    <Col lg={10} md={12} sm={12} className="mt-1">
                                                        <Row className="justify-content-md-end justify-content-center align-items-center">
                                                            <Col className="col-md-auto">
                                                                <div className="d-flex gap-1">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => gotoPage(1)}
                                                                        disabled={this.state.data.page === 1}
                                                                    >
                                                                        {"<<"}
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={previousPage}
                                                                        disabled={this.state.data.page === 1}
                                                                    >
                                                                        {"<"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                            <Col className="col-md-auto d-none d-md-block">
                                                                Página{" "}
                                                                <strong>
                                                                    {this.state.data.page} de {pages}
                                                                </strong>
                                                            </Col>
                                                            <Col className="col-md-auto">
                                                                <Input
                                                                    type="number"
                                                                    min={1}
                                                                    style={{ width: 70, textAlign: 'center' }}
                                                                    max={pages}
                                                                    defaultValue={this.state.data.page}
                                                                    onChange={onChangeInInput}
                                                                />
                                                            </Col>

                                                            <Col className="col-md-auto">
                                                                <div className="d-flex gap-1">
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={nextPage}
                                                                        disabled={this.state.data.page === pages}
                                                                    >
                                                                        {">"}
                                                                    </Button>
                                                                    <Button
                                                                        color="primary"
                                                                        onClick={() => gotoPage(pages)}
                                                                        disabled={this.state.data.page === pages}
                                                                    >
                                                                        {">>"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    {this.state.width < 768 ?
                                                        <Col sm={12} className="mt-2">
                                                            Página{" "}
                                                            <strong>
                                                                {this.state.data.page} de {pages}
                                                            </strong>
                                                        </Col>
                                                        : <></>}
                                                </Row>
                                            </div>
                                            :
                                            <>
                                                <Row>
                                                    <Col xl={12} lg={12} md={12} sm={12}>
                                                        <div className="text-center">
                                                            <h5>Sin registros</h5>
                                                            <p>Intenta con otros parámetros de búsqueda</p>
                                                            <i className="ri-search-line font-size-30"></i>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </>
                                        }

                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </React.Fragment>
        );
    }
}

CFDI.propTypes = {
    cfdis: PropTypes.any,
    onGetCFDIs: PropTypes.func,
};

const mapStateToProps = ({ CFDI }) => ({
    cfdis: CFDI.cfdis
});

const mapDispatchToProps = (dispatch) => ({
    onGetCFDIs: (data, history) => dispatch(getCFDIs(data, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CFDI);
