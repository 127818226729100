import axios from "axios";
const baseUrl = 'https://clientes.tabimax.com.mx';

export const login = (data) => {
    const headers = {
        "Content-Type": "application/json"
    }
    const endpoint = `${baseUrl}/api/auth/login/`;
    return axios({
        method: 'post',
        url: endpoint,
        data: data,
        headers: headers
    });
}

export const get = (url, params) => {
    const { token } = JSON.parse(localStorage.getItem('authUser'));
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }

    const endpoint = (`${baseUrl}${url}${ params ? '/' + params : '' }`);
    return axios({
        method: 'get',
        url: endpoint,
        headers: headers
    });
}

export const post = (url, data) => {
    const { token } = JSON.parse(localStorage.getItem('authUser'));
    const headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`
    }
    const endpoint = `${baseUrl}${url}`;
    return axios({
        method: 'post',
        url: endpoint,
        data: data,
        headers: headers
    });
}

export const upload = (url, data) => {
    const { token } = JSON.parse(localStorage.getItem('authUser'));
    const headers = {
        "Content-Type": "multipart/form-data",
        "Authorization": `Bearer ${token}`
    }
    const endpoint = `${baseUrl}${url}`;
    return axios({
        method: 'post',
        url: endpoint,
        data: data,
        headers: headers
    });
}