import { takeEvery, put, call } from "redux-saga/effects";

import { Loading, Warning, Close, Question, Error } from '../../common/sweetalert'

import { GET_REFERRALS, GET_REFERRALS_ARTICLES, SHOW_TRACKING } from "./actionTypes";
import { getReferralsSuccess, getReferralsFail } from "./actions";

import { getReferrals, getReferralsArticles } from "../../services/referrals.services";
import { refreshTkn } from "../../services/common.service"

function* onGetReferrals({ payload: { data, history } }) {
    try {
        Loading('Obteniendo registros');
        const response = yield call(getReferrals, data);
        yield put(getReferralsSuccess(response));
        Close();
    } catch (error) {
        if (error) {
            if (error.data && error.data.code) {
                const err_code = error.data.code;
                if (err_code === 'EXPIRED') {
                    Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                        if (res) {
                            const refresh = await refreshToken();
                            if (refresh) window.location.reload();
                        } else {
                            localStorage.removeItem("authUser");
                            history.push('/login');
                            window.location.reload();
                        }
                    })
                } else {
                    Warning('Ocurrió un problema inesperado', "No fue posible obtener las remisiones.");
                    yield put(getReferralsFail(error));
                }
            } else {
                Warning('Ocurrió un problema inesperado', "No fue posible obtener las remisiones.");
                yield put(getReferralsFail(error));
            }
        } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
        }
    }
}

function* onGetReferralsArticles({ payload: { data, history } }) {
    try {
        Loading('Obteniendo artículos');
        const params = {
            id: data.current_registry.id,
            mode: 'articles'
        }
        const response = yield call(getReferralsArticles, params)

        let copy_results = { ...data };
        let { results } = copy_results

        results = results.map(elem => {
            if (elem.id === data.current_registry.id) {
                elem.articles = response.articles;
                elem.subtotal = response.subtotal;
                elem.iva = response.iva;
                elem.total = response.total;
                return elem;
            } else {
                delete elem.articles;
                return elem;
            }
        });

        const new_obj = {
            pages: data.pages,
            total: data.total,
            results: results
        }

        yield put(getReferralsSuccess(new_obj));
        Close();
    } catch (error) {
        if (error) {
            if (error.data && error.data.code) {
                const err_code = error.data.code;
                if (err_code === 'EXPIRED') {
                    Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                        if (res) {
                            const refresh = await refreshToken();
                            if (refresh) window.location.reload();
                        } else {
                            localStorage.removeItem("authUser");
                            history.push('/login');
                            window.location.reload();
                        }
                    })
                } else {
                    Warning('Ocurrió un problema inesperado', "No fue posible obtener los artículos.");
                    yield put(getReferralsFail(error));
                }
            } else {
                Warning('Ocurrió un problema inesperado', "No fue posible obtener los artículos.");
                yield put(getReferralsFail(error));
            }
        } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
        }
    }
}

function* onShowTracking({ payload: { data, history } }) {
    try {

        if (data.current_registry.show_tracking === 1) {

            Loading('Obteniendo estatus de seguimiento');
            const params = {
                id: data.current_registry.id,
                mode: 'tracking'
            }
            const response = yield call(getReferralsArticles, params)
            let tracking = {};
            let finished = false;

            if (response.success) {
                tracking = response.tracking[0]
                if (tracking && response.finished.date) finished = true;
            }

            let copy_results = { ...data };
            let { results } = copy_results;

            results = results.map(elem => {
                if (elem.id === data.current_registry.id) {
                    elem.tracking = { show: true, data: tracking, finished: finished, finished_date: response.finished.date };
                    return elem;
                }
                return elem;
            });

            const new_obj = {
                pages: data.pages,
                total: data.total,
                results: results
            }

            yield put(getReferralsSuccess(new_obj));
            Close();
        } else {
            yield put(getReferralsSuccess(data));
        }
    } catch (error) {
        if (error) {
            if (error.data && error.data.code) {
                const err_code = error.data.code;
                if (err_code === 'EXPIRED') {
                    Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
                        if (res) {
                            const refresh = await refreshToken();
                            if (refresh) window.location.reload();
                        } else {
                            localStorage.removeItem("authUser");
                            history.push('/login');
                            window.location.reload();
                        }
                    })
                } else {
                    Warning('Ocurrió un problema inesperado', "No fue posible obtener el estatus de seguimiento.");
                    yield put(getReferralsFail(error));
                }
            } else {
                Warning('Ocurrió un problema inesperado', "No fue posible obtener el estatus de seguimiento.");
                yield put(getReferralsFail(error));
            }
        } else {
            Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
        }
    }
}

async function refreshToken() {
    try {
        const response = await refreshTkn()
        if (response.success) {
            const user = JSON.parse(localStorage.getItem('authUser'));
            let user_copy = { ...user };
            let { token } = user_copy;
            token = response.token;
            user['token'] = token;
            localStorage.setItem("authUser", JSON.stringify(user));
            return true;
        }
    } catch (error) {
        return error;
    }
}

function* referralsSaga() {
    yield takeEvery(GET_REFERRALS, onGetReferrals)
    yield takeEvery(GET_REFERRALS_ARTICLES, onGetReferralsArticles)
    yield takeEvery(SHOW_TRACKING, onShowTracking)
}

export default referralsSaga;