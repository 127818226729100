import {
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS
} from "./actionTypes";

export const getInvoices = (data, history) => ({
  type: GET_INVOICES,
  payload: { data, history }
});

export const getInvoicesSuccess = invoices => ({
  type: GET_INVOICES_SUCCESS,
  payload: invoices,
});

export const getInvoicesFail = error => ({
  type: GET_INVOICES_FAIL,
  payload: error,
});
