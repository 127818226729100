import {
    GET_DASHBOARD_DATA,
    GET_DASHBOARD_SUCCESS,
    GET_DASHBOARD_FAIL
} from './actionTypes'

export const getDashboard = (params, history) => ({
    type: GET_DASHBOARD_DATA,
    payload: { params, history },
});

export const getDashboardDataSuccess = data => ({
    type: GET_DASHBOARD_SUCCESS,
    payload: data,
});

export const getDashboardDataFail = error => ({
    type: GET_DASHBOARD_FAIL,
    payload: error,
});