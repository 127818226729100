import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

const MySwal = withReactContent(Swal)

export const Success = (title, text) => {

    const options = {
        icon: 'success',
        html: title || 'Exito',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#339999',
        didOpen: () => MySwal.hideLoading()
    };

    if(title) options.title = title;
    if(text) options.html = text;

    return MySwal.fire(options);
}

export const Warning = (title, text) => {

    const options = {
        icon : 'warning',
        html : title || 'Advertencia',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#339999',
        didOpen: () => MySwal.hideLoading()
    };

    if(title) options.title = title;
    if(text) options.html = text;

    return MySwal.fire(options);
}

export const Error = (title, text) => {

    let options = {
        icon : 'error',
        title : title || 'Ocurrió un problema inesperado',
        html : text || 'No fue posible procesar la solicitud. Intenta de nuevo más tarde.',
        confirmButtonText: 'Aceptar',
        confirmButtonColor: '#339999',
        didOpen: () => MySwal.hideLoading()
    };

    return MySwal.fire(options);
}

export const Question = (title, text, buttonOne, buttonTwo) => {
    return new Promise((resolve, reject) => {
        let options = {
            icon: 'info',
            title : title || 'Confirmación',
            html : text || '¿Está seguro de realizar esta acción?',
            allowOutsideClick: false,
            showCancelButton: true,
            confirmButtonText: buttonOne || 'Aceptar',
            cancelButtonText: buttonTwo || 'Cancelar',
            allowEscapeKey: true,
            reverseButtons: true,
            showLoaderOnConfirm: false,
            confirmButtonColor: '#3b3c8a',
            cancelButtonColor: '#5a5d5a',
            didOpen: () => {
                MySwal.hideLoading();
            },
        };
    
        if(title) options.title = title;
        if(text) options.html = text;
    
        MySwal.fire(options).then(result => {
            if (result.isConfirmed)
                resolve(true);
            else
                resolve(false);
            Close();
        });
    })
}

export const Loading = (title, text) => {
    MySwal.fire({
        title: title || 'Cargando...',
        text: text || 'Espera un momento por favor',
        html: '',
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
            MySwal.showLoading();
        },
    });
}

export const Close = () => {
    MySwal.close();
}

export const Exec = (options) => {
    return MySwal.fire(options);
}