import { takeEvery, put, call } from "redux-saga/effects"

import { Loading, Warning, Close, Question, Error } from '../../common/sweetalert'

import { GET_QUOTES, GET_QUOTES_ARTICLES } from "./actionTypes";
import { getQuotesSuccess, getQuotesFail } from "./actions";

import { getQuotes, getQuotesArticles } from "../../services/quotes.services";
import { refreshTkn } from "../../services/common.service"


function* onGetQuotes({ payload: { data, history } }) {
  try {
    Loading('Obteniendo registros');
    const response = yield call(getQuotes, data);
    yield put(getQuotesSuccess(response));
    Close();
  } catch (error) {
    if (error) {
      if (error.data && error.data.code) {
        const err_code = error.data.code;
        if (err_code === 'EXPIRED') {
          Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
            if (res) {
              const refresh = await refreshToken();
              if (refresh) window.location.reload();
            } else {
              localStorage.removeItem("authUser");
              history.push('/login');
              window.location.reload();
            }
          })
        } else {
          Warning('Ocurrió un problema inesperado', "No fue posible obtener las cotizaciones.");
          yield put(getQuotesFail(error));
        }
      } else {
        Warning('Ocurrió un problema inesperado', "No fue posible obtener las cotizaciones.");
        yield put(getQuotesFail(error));
      }
    } else {
      Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
    }
  }
}

function* onGetQuotesArticles({ payload: { data, history } }) {
  try {
    Loading('Obteniendo artículos');
    const response = yield call(getQuotesArticles, data.current_registry.id);

    let copy_results = { ...data };
    let { results } = copy_results;

    results = results.map(elem => {
      if (elem.id === data.current_registry.id) {
        elem.articles = response.articles;
        elem.subtotal = response.subtotal;
        elem.iva = response.iva;
        elem.total = response.total;
        return elem;
      } else {
        delete elem.articles;
        return elem;
      }
    });

    const new_obj = {
      pages: data.pages,
      total: data.total,
      results: results
    }

    yield put(getQuotesSuccess(new_obj));
    Close();
  } catch (error) {
    if (error) {
      if (error.data && error.data.code) {
        const err_code = error.data.code;
        if (err_code === 'EXPIRED') {
          Question('Sesión finalizada', 'Tu sesión ha finalizado. ¿Deseas renovarla o cerrar la sesión?', 'Renovar', 'Cerrar sesión').then(async (res) => {
            if (res) {
              const refresh = await refreshToken();
              if (refresh) window.location.reload();
            } else {
              localStorage.removeItem("authUser");
              history.push('/login');
              window.location.reload();
            }
          })
        } else {
          Warning('Ocurrió un problema inesperado', "No fue posible obtener los artículos.");
          yield put(getQuotesFail(error));
        }
      } else {
        Warning('Ocurrió un problema inesperado', "No fue posible obtener los artículos.");
        yield put(getQuotesFail(error));
      }
    } else {
      Error('Sin conexión', 'Al parecer la conexión ha fallado. Intenta de nuevo más tarde');
    }
  }
}

async function refreshToken() {
  try {
    const response = await refreshTkn()
    if (response.success) {
      const user = JSON.parse(localStorage.getItem('authUser'));
      let user_copy = { ...user };
      let { token } = user_copy;
      token = response.token;
      user['token'] = token;
      localStorage.setItem("authUser", JSON.stringify(user));
      return true;
    }
  } catch (error) {
    return error;
  }
}

function* quotesSaga() {
  yield takeEvery(GET_QUOTES, onGetQuotes)
  yield takeEvery(GET_QUOTES_ARTICLES, onGetQuotesArticles)
}

export default quotesSaga;
