import { get } from '../common/axios';

const getDashboardDataService = (params) => {
    return get(`/api/dashboard/services?client=${params.client}&rfc=${params.rfc}&accounts=${params.accounts}`).then(response => {
        if(response.status >= 200 || response.status <= 299)
            return response.data
    }).catch(err => {
        throw err.response
    });
}

export { getDashboardDataService }