import {
  GET_CFDIS_SUCCESS,
  GET_CFDIS_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  cfdis: {},
}

const CFDI = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CFDIS_SUCCESS:
      return {
        ...state,
        cfdis: action.payload,
      }

    case GET_CFDIS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
      
    default:
      return state;
  }
}

export default CFDI;
