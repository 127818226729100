import {
    GET_DOCUMENTS_SUCCESS,
    GET_DOCUMENTS_FAILS,
    UPLOAD_DOCUMENTS_SUCCESS,
    UPLOAD_DOCUMENTS_FAILS
} from "./actionTypes"

const INIT_STATE = {
    docs: []
}

const Documents = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DOCUMENTS_SUCCESS:
            return {
                ...state,
                docs: action.payload
            }

        case GET_DOCUMENTS_FAILS:
            return {
                ...state,
                error: action.payload
            }

        case UPLOAD_DOCUMENTS_SUCCESS:
            return {
                ...state,
                result: action.payload
            }

        case UPLOAD_DOCUMENTS_FAILS:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}

export default Documents;