import React, { Component } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

//Import Components
import InvoicesHistoryCard from "./InvoicesHistoryCard";
import InvoicesCard from "./InvoicesCard";
import RecentActivitiesCard from "./RecentActivitiesCard";
import LatestCFDIs from "./LatestCFDIs";

import { getDashboard } from "../../store/actions";

class Dashboard extends Component {
    constructor(props) {
        const [doc] = document.getElementsByClassName("grecaptcha-badge");
        if (doc) doc.setAttribute("style", "visibility:hidden");
        super(props);
        this.state = {
            params: {
                rfc: JSON.parse(localStorage.getItem('authUser')).rfc,
                client: JSON.parse(localStorage.getItem('authUser')).username,
                accounts: JSON.parse(localStorage.getItem('totalAccounts'))
            },
            loading: false,
            breadcrumbItems: [
                { title: "NOVACERAMIC", link: "/" },
                { title: "Resumen", link: "#" },
            ],
            width: 0,
            height: 0
        }
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentDidMount() {
        const { onGetDashboardData } = this.props;
        onGetDashboardData(this.state.params, this.props.history);
        this.setState({ loading: true });
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    render() {

        const { data } = this.props.data;

        return (
            <React.Fragment>
                {data && this.state.loading ?
                    <div className="page-content">
                        <Container fluid>

                            <Breadcrumbs title="Resumen" breadcrumbItems={this.state.breadcrumbItems} />
                            <Row>
                                <Col xl={12}>
                                    <Row>
                                        {
                                            data.reports.map((report, key) =>
                                                <Col key={key} sm={6} md={6} lg={6} xl={6} xxl={3}>
                                                    <Card>
                                                        <CardBody
                                                            style={ this.state.width >= 1400 && this.state.width <= 1760 ? { padding: "20px 10px"} : null }
                                                        >
                                                            {
                                                                this.state.width >= 1400 && this.state.width <= 1760 ?
                                                                    <React.Fragment>
                                                                        <div className="d-flex">
                                                                            <div className="overflow-hidden" style={{ width: "30px", margin: "auto" }}>
                                                                                <i className={`${report.icon} font-size-18`}></i>
                                                                            </div>
                                                                            <div className="flex-1 overflow-hidden">
                                                                                <p className="text-truncate font-size-14 mb-2">{report.title}</p>
                                                                                <h4
                                                                                    style={ this.state.width >= 1400 && this.state.width <= 1760 ? { fontSize: "16px" } : null }
                                                                                    className={`${report.value && parseFloat(report.value.replace("$", "")) < 0 ? "text-success" : ""} mb-0`}
                                                                                >
                                                                                    {report.value}
                                                                                </h4>
                                                                            </div>
                                                                            {
                                                                                report.percentage || report.percentage === 0 ?
                                                                                    <div className="overflow-hidden text-center" style={{ width: "62px", margin: "auto" }}>
                                                                                        <div
                                                                                            style={{ fontSize: "11px" }}
                                                                                            className={`
                                                                                                badge ${report.percentage < 61 ? "badge-soft-success"
                                                                                                        : report.percentage >= 61 && report.percentage < 81 ? "badge-soft-warning"
                                                                                                        : "badge-soft-danger"}`
                                                                                            }
                                                                                        >{report.percentage} %</div>
                                                                                    </div>
                                                                                    : <></>
                                                                            }
                                                                            {
                                                                                report.days || report.days === 0 ?
                                                                                    <div className="overflow-hidden text-center" style={{ width: "62px", margin: "auto" }}>
                                                                                        <div
                                                                                            style={{ fontSize: "11px" }}
                                                                                            className={`
                                                                                                    badge ${report.days <= 0 ? "badge-soft-success"
                                                                                                        : report.days >= 1 && report.days < 15 ? "badge-soft-warning"
                                                                                                        : "badge-soft-danger"}`
                                                                                            }
                                                                                        >
                                                                                            { report.days < 0 ? <div style={{ marginBottom: "3px" }}>Vence en:</div> : null }
                                                                                            { report.days < 0 ? report.days.toString().replace('-','') : report.days } Días
                                                                                        </div>
                                                                                    </div>
                                                                                    : <></>
                                                                            }
                                                                        </div>
                                                                        
                                                                    </React.Fragment>
                                                                    
                                                                    :
                                                                    <div className="d-flex">
                                                                        <div className="overflow-hidden" style={{ width: "45px", margin: "auto" }}>
                                                                            <i className={`${report.icon} font-size-26`}></i>
                                                                        </div>
                                                                        <div className="flex-1 overflow-hidden">
                                                                            <p className="text-truncate font-size-14 mb-2">{report.title}</p>
                                                                            <h4
                                                                                style={this.state.width >= 1400 && this.state.width <= 1760 ? { fontSize: "16px" } : null}
                                                                                className={`${report.value && parseFloat(report.value.replace("$", "")) < 0 ? "text-success" : ""} mb-0`}
                                                                            >
                                                                                {report.value}
                                                                            </h4>
                                                                        </div>
                                                                        {
                                                                            report.percentage || report.percentage === 0 ?
                                                                                <div className="overflow-hidden text-center" style={{ width: "75px", margin: "auto" }}>
                                                                                    <div
                                                                                        className={`
                                                                                                    badge ${report.percentage < 61 ? "badge-soft-success"
                                                                                                : report.percentage >= 61 && report.percentage < 81 ? "badge-soft-warning"
                                                                                                    : "badge-soft-danger"} font-size-13`
                                                                                        }
                                                                                    >{report.percentage} %</div>
                                                                                </div>
                                                                                : <></>
                                                                        }
                                                                        {
                                                                            report.days || report.days === 0 ?
                                                                                <div className="overflow-hidden text-center" style={{ width: "75px", margin: "auto" }}>
                                                                                    <div
                                                                                        className={`
                                                                                                    badge ${report.days <= 0 ? "badge-soft-success"
                                                                                                : report.days >= 1 && report.days < 15 ? "badge-soft-warning"
                                                                                                    : "badge-soft-danger"} font-size-13`
                                                                                        }
                                                                                    >
                                                                                        { report.days < 0 ? <div style={{ marginBottom: "3px" }}>Vence en:</div> : null }
                                                                                        { report.days < 0 ? report.days.toString().replace('-','') : report.days } Días
                                                                                    </div>
                                                                                </div>
                                                                                : <></>
                                                                        }
                                                                    </div>
                                                            }
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            )
                                        }
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xl={6}>
                                    <InvoicesHistoryCard invoicesList={data.listing} history={this.props.history} />
                                </Col>

                                <Col xl={6}>
                                    <LatestCFDIs cfdiList={data.listing} width={this.state.width} />
                                </Col>

                            </Row>

                            <Row>
                                <Col xl={6}>
                                    <InvoicesCard pieChart={data.listing} />
                                </Col>

                                <Col xl={6}>
                                    <RecentActivitiesCard activityList={data.listing} />
                                </Col>
                            </Row>

                        </Container>
                    </div>
                    : <></>
                }

            </React.Fragment>
        );
    }
}

Dashboard.propTypes = {
    data: PropTypes.any,
    onGetDashboardData: PropTypes.func
}

const mapStateToProps = ({ Dashboard }) => ({
    data: Dashboard.data
});

const mapDispatchToProps = (dispatch) => ({
    onGetDashboardData: (data, history) => dispatch(getDashboard(data, history)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
