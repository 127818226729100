import {
  GET_CFDIS,
  GET_CFDIS_FAIL,
  GET_CFDIS_SUCCESS
} from "./actionTypes"

export const getCFDIs = (data, history) => ({
  type: GET_CFDIS,
  payload: { data, history }
})

export const getCFDIsSuccess = cfdis => ({
  type: GET_CFDIS_SUCCESS,
  payload: cfdis,
})

export const getCFDIsFail = error => ({
  type: GET_CFDIS_FAIL,
  payload: error,
})
