import { all } from 'redux-saga/effects'

//public
import accountSaga from './auth/register/saga';
import loginSaga from './auth/login/saga';
import forgetSaga from './auth/forgetpwd/saga';
import LayoutSaga from './layout/saga';

// Dashboard
import dashboardSaga from './dashboard/saga';

// CFDI
import cfdiSaga from "./CFDI/saga"

// Invoices 
import invoicesSaga from "./invoices/saga";

// Quotes
import quotesSaga from './quotes/saga';

// Orders
import ordersSaga from "./orders/saga";

// Referrals
import referralsSaga from "./referrals/saga";

// Documents
import documentsSaga from "./documents/saga";

export default function* rootSaga() {
    yield all([
        // public
        accountSaga(),
        loginSaga(),
        forgetSaga(),
        // private
        LayoutSaga(),
        dashboardSaga(),
        cfdiSaga(),
        invoicesSaga(),
        quotesSaga(),
        ordersSaga(),
        referralsSaga(),
        documentsSaga(),
    ])
}