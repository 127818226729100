import { get, post } from "../common/axios"

const getReferrals = (data) => {
    return post ('/api/referrals/list/', data).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

const getReferralsArticles = (params) => {
    return get (`/api/referrals/list?id=${params.id}&mode=${params.mode}`).then(response => {
        if (response.status >= 200 || response.status <= 299)
            return response.data;
        throw response.data;
    }).catch(err => {
        throw err.response;
    });
}

export { getReferrals, getReferralsArticles };