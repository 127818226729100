import React from "react";
import ReactApexChart from "react-apexcharts";
import { useHistory } from 'react-router-dom';

const RevenueAnalyticsChart = (data) => {
    const history = useHistory();

    const { invoice_list } = data; 

    let concluded = invoice_list.map(e => {
        return e.paid
    });

    let pending = invoice_list.map(e => {
       return e.pending
    });

    let categories = invoice_list.map(e => {
        return e.date;
    })

    const goToInvoices = (index) => {
        if (index === 1) {
            history.push({ pathname: '/invoices' });
        }
    }

    const series = [
        { 
            name: 'PAGADAS', 
            data: concluded 
        },
        {
            name: 'PENDIENTES', 
            data: pending 
        },
    ]

    var options = {
        series: series,
        chart: {
            type: 'bar',
            stacked: true,
            zoom: {
                enabled: !1
            },
            toolbar: {
                show: !1
            },
            events: {
                legendClick: function(chartContext, seriesIndex, config) {
                    goToInvoices(seriesIndex)
                }
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    position: 'bottom',
                    offsetX: -10,
                    offsetY: 0
                }
            }
        }],
        colors: ['#009fe3','#DD1200'],
        plotOptions: {
            bar: {
                horizontal: false,
                borderRadius: 10,
                dataLabels: {
                    total: {
                        enabled: true,
                        style: {
                            fontSize: '13px',
                            fontWeight: 900
                        }
                    }
                }
            },
        },
        xaxis: {
            categories: categories,
            title: {
                text: "Mes"
            }
        },
        legend: {
            position: 'bottom',
            offsetY: 12
        },
        fill: {
            opacity: 1
        }
    };

    return (
        <React.Fragment>
            <ReactApexChart options={options} series={series} type="bar" height={380} />
        </React.Fragment>
    );
};


export { RevenueAnalyticsChart }