import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';

// Dashboard
import Dashboard from './dashboard/reducer';

// CFDI
import CFDI from "./CFDI/reducer"

// Invoices 
import Invoices from "./invoices/reducer";

// Quotes
import Quotes from "./quotes/reducer";

// Orders
import Orders from "./orders/reducer";

// Referrals
import Referrals from "./referrals/reducer";

// Documents
import Documents from "./documents/reducer";

const rootReducer = combineReducers({

    // public
    Layout,

    // Authentication
    Account,
    Login,
    Forget,
    Dashboard,
    CFDI,
    Invoices,
    Quotes,
    Orders,
    Referrals,
    Documents,
});

export default rootReducer;