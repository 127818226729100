import React from "react"
import PropTypes from 'prop-types'
import { Col, Card, CardBody, Row, Container } from "reactstrap"
import moment from 'moment';

const TrackView = ({ referral, onChangeView }) => {
    return (
        <React.Fragment>
            <Row className="mb-1">
                <Col>
                    <button style={{ width: "100%" }} className="btn btn-primary btn-sm text-center" onClick={onChangeView}>Regresar a listado</button>
                </Col>
            </Row>

            <div className="page-content" style={{ padding: "0" }}>
                <Container fluid>
                    <Row>
                        <Col>
                            <div className="text-center mt-2">
                                <h5>Seguimiento de remisión ({
                                    referral.tracking.finished ? 'ENTREGADO' :
                                        referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CARGANDO' ? 'PREPARANDO' :
                                            referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CONCLUIDO' ? 'EN PROGRESO' : 'INICIADO'

                                })</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl={10}>
                            <div
                                className={`
                                    ${referral.tracking.finished ? 'thirdStep' :
                                        referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CARGANDO' ? 'firstStep' :
                                            referral.tracking.data && referral.tracking.data.status && referral.tracking.data.status === 'CONCLUIDO' ? 'secondStep' : ''
                                    }
                                    timeline
                                `}
                                dir="ltr"
                            >
                                <div className="timeline-item">
                                    <div className="timeline-block">
                                        <Card className="timeline-box">
                                            <CardBody style={{ paddingBottom: "7px" }}>
                                                <span className="timeline-icon"></span>
                                                <div className="timeline-date">
                                                    <i
                                                        className="mdi mdi-circle-medium circle-dot"
                                                        style={{ backgroundColor: "#3B3C8A" }}>
                                                    </i> Iniciado
                                                </div>
                                                <div className="text-muted mt-2">
                                                    {
                                                        referral.tracking.data && referral.tracking.data.date_emission ?
                                                            <span>{moment.utc(referral.tracking.data.date_emission, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                            : <></>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                                <div className="timeline-item">
                                    <div className="timeline-block">
                                        <Card className="timeline-box">
                                            <CardBody style={{ paddingBottom: "7px" }}>
                                                <span className="timeline-icon"></span>
                                                <div
                                                    className="timeline-date"
                                                    style={{
                                                        backgroundColor: referral.tracking.data && (referral.tracking.data.status === 'CARGANDO' || referral.tracking.data.status === 'CONCLUIDO') ?
                                                            "#3B3C8A"
                                                            : "#A3A3A3"
                                                    }}
                                                >
                                                    <i className="mdi mdi-circle-medium circle-dot"></i> Preparando
                                                </div>
                                                <div className="text-muted mt-2">
                                                    {
                                                        referral.tracking.data && referral.tracking.data.date_entry ?
                                                            <span>{moment.utc(referral.tracking.data.date_entry, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                            : <></>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>

                                <div className="timeline-item">
                                    <div className="timeline-block">
                                        <Card className="timeline-box">
                                            <CardBody style={{ paddingBottom: "7px" }}>
                                                <span className="timeline-icon"></span>
                                                <div
                                                    className="timeline-date"
                                                    style={{
                                                        backgroundColor: referral.tracking.data && referral.tracking.data.status === 'CONCLUIDO' ?
                                                            "#3B3C8A"
                                                            : "#A3A3A3"
                                                    }}
                                                >
                                                    <i className="mdi mdi-circle-medium circle-dot"></i> En transito
                                                </div>
                                                <div className="text-muted mt-2">
                                                    {
                                                        referral.tracking.data && referral.tracking.data.date_departure ?
                                                            <span>{moment.utc(referral.tracking.data.date_departure, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                            : <></>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                                <div className="timeline-item">
                                    <div className="timeline-block">
                                        <Card className="timeline-box">
                                            <CardBody style={{ paddingBottom: "7px" }}>
                                                <span className="timeline-icon"></span>
                                                <div
                                                    className="timeline-date"
                                                    style={{ backgroundColor: referral.tracking.finished ? "#3B3C8A" : "#A3A3A3" }}
                                                >
                                                    <i className="mdi mdi-circle-medium circle-dot"></i> Entregado
                                                </div>
                                                <div className="text-muted mt-2">
                                                    {
                                                        referral.tracking.finished ?
                                                            <span>{moment.utc(referral.tracking.finished_date, 'YYYY-MM-DD HH:mm:ss.SSS').format('DD/MM/yyyy') || 'S/D'}</span>
                                                            : <></>
                                                    }
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

TrackView.propTypes = {
    onChangeView: PropTypes.func
}

export default TrackView
