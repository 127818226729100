import { get } from '../common/axios';

const refreshTkn = () => {
    return get(`/api/token/refresh`).then(response => {
        if(response.status >= 200 || response.status <= 299)
            return response.data
    }).catch(err => {
        throw err.response
    });
}

export { refreshTkn }