import {
    GET_QUOTES_SUCCESS,
    GET_QUOTES_FAIL
} from "./actionTypes";

const INIT_STATE = {
    quotes: []
}

const Quotes = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_QUOTES_SUCCESS:
            return {
                ...state,
                quotes: action.payload
            }
        
        case GET_QUOTES_FAIL:
            return {
                ...state,
                quotes: action.payload
            }

        default:
            return state
    }
}

export default Quotes;