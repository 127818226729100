import React from "react"
import PropTypes from 'prop-types'
import { Col, Card, CardBody, Row } from "reactstrap"
import moment from 'moment';

import MUL0612NATU from "../../assets/images/product/MUL0612NATU.png";
import MUL1212NATU224 from "../../assets/images/product/MUL1212NATU(224).png";
import NBH2010 from "../../assets/images/product/NBH2010.png";
import NBH2010RNATU from "../../assets/images/product/NBH2010RNATU.png";
import NBH2012 from "../../assets/images/product/NBH2012.png";
import NBM2010 from "../../assets/images/product/NBM2010.png";
import NBM2010RNATU from "../../assets/images/product/NBM2010RNATU.png";
import NBM2012 from "../../assets/images/product/NBM2012.png";
import NOL092480V45 from "../../assets/images/product/NOL092480V(45).png";
import NOVDALA1520 from "../../assets/images/product/NOVDALA1520.png";
import TAB10 from "../../assets/images/product/TAB10.png";
import TAB12 from "../../assets/images/product/TAB12.png";
import TAB15 from "../../assets/images/product/TAB15.png";
import TAC10 from "../../assets/images/product/TAC10.png";
import TAC12 from "../../assets/images/product/TAC12.png";
import TAC15 from "../../assets/images/product/TAC15.png";
import VIN0612NATU from "../../assets/images/product/VIN0612NATU.png";
import VIN1212NATU224 from "../../assets/images/product/VIN1212NATU(224).png";

const DetailView = ({ data, onChangeView }) => {

  const productImages = {
    MUL0612NATU,
    MUL1212NATU224,
    NBH2010,
    NBH2010RNATU,
    NBH2012,
    NBM2010,
    NBM2010RNATU,
    NBM2012,
    NOL092480V45,
    NOVDALA1520,
    TAB10,
    TAB12,
    TAB15,
    TAC10,
    TAC12,
    TAC15,
    VIN0612NATU,
    VIN1212NATU224,
  }

  const getProduct = (img) => {
    const replaceName = img.replace(/[\(\)]/g, '');
    return productImages[replaceName];
  }


  return (
    <React.Fragment>
      <Row className="mb-1">
        <Col>
          <button style={{ width: "100%" }} className="btn btn-primary btn-sm text-center" onClick={ onChangeView }>Regresar a listado</button>
        </Col>
      </Row>
      
      <Card className="font-size-12">
        <CardBody style={{ padding: "7px 20px" }}>
        <Row className="font-size-13">
          <Col xs={7} sm={7} md={7} style={{ padding: "0 2px 0 0" }}>
            <div>{data.movement}</div>
          </Col>
          <Col xs={5} sm={5} md={5} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
            <div>{data.situation}</div>
          </Col>
          </Row>
          <hr style={{ margin: "3px -20px 8px"}}/>
          <Row>
            <Col xs={4} sm={4} md={4} style={{ padding: "0 2px 0 0" }}>
              <div>Emisión</div>
              <div>{moment(data.emission_date).format('DD/MM/yyyy')}</div>
              <br />
              <div><b>Zona:</b> {data.zone}</div>
            </Col>
            <Col xs={5} sm={5} md={5} className="text-center" style={{ padding: "0 1px" }}>
              <div className="font-size-14">{data.total_price}</div>
              <br />
              <div>{data.name}<br />{data.address}</div>
            </Col>
            <Col xs={3} sm={3} md={3} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
              <div>Requerido</div>
              <div>{moment(data.date_required).format('DD/MM/yyyy')}</div>
              <br />
              <div><b>Ruta:</b> {data.route}</div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card className="font-size-12">
        <CardBody style={{ padding: "7px 20px" }}>
          {data.articles.map((art, key) => (
            <React.Fragment key={key}>
              <Row>
                <Col xs={2} sm={2} md={2} style={{ margin: "auto" }}> 
                  <img src={ getProduct(art.article) } alt={art.article} height="35" className="text-center"/>
                </Col>
                <Col xs={7} sm={7} md={7}>
                  <div>{art.article} - {art.description}</div>
                  <div>{art.price}</div>
                </Col>
                <Col xs={3} sm={3} md={3} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                  <div>
                    Cantidad <br/>
                    <b>{art.quantity.toLocaleString()}</b><br/>
                    Subtotal <br/>
                    <b>{art.subtotal !== null ? art.subtotal : "$0.00"}</b>
                  </div>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col xs={4} sm={4} md={4}>
                  <i className="fas fa-th"></i> <span>{art.part_num}</span>
                </Col>
                <Col xs={4} sm={4} md={4} className="text-center">
                  <i className="fas fa-spinner"></i> <span>{art.pending_quantity !== null ? art.pending_quantity.toLocaleString() : 0}</span>
                </Col>
                <Col xs={4} sm={4} md={4} style={{ padding: "0 0 0 2px", textAlign: "right" }}>
                  <i className="fas fa-table"></i> <span>{art.pending_palets !== null ? art.pending_palets : 0}</span>
                </Col>
              </Row>
              <hr style={{ margin: "5px 0"}} />
            </React.Fragment>

          ))}
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

DetailView.propTypes = {
  onChangeView: PropTypes.func
}

export default DetailView
