import React, { Component } from 'react';
import { Card, CardBody, Row, Col } from "reactstrap";

//Import Charts
import ReactApexChart from 'react-apexcharts';
import "./dashboard.scss";

class Invoices extends Component {
    render() {
        let chartData = {
            series: [],
            options: {
                labels: [],
                plotOptions: {
                    pie: {
                        donut: {
                            size: '75%'
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                legend: {
                    show: false,
                },
                colors: ['#009fe3', '#DD1200'],
    
            }
        }
        let concluded = 0;
        let pending = 0;
        this.props.pieChart.filter(elem => {
            if(elem.pieChart) {
                elem.pieChart.forEach(e => {
                    chartData.series.push(e[1]);
                    chartData.options.labels.push(e[0]);
                    if(e[0] === 'CONCLUIDO') concluded = e[1];
                    if(e[0] === 'PENDIENTE') pending = e[1];
                });
            }
        });

        return (
            <React.Fragment>
                <Card>
                    <CardBody>
                        <h4 className="card-title mb-4">Facturas</h4>

                        <Row>
                            <Col xs={6}>
                                <div className="text-center mt-1">
                                    <span><i style={{ color: '#009fe3' }} className="mdi mdi-circle font-size-18 me-1"></i> Pagadas</span>
                                    <div>
                                        <b className='font-size-20'>{concluded}</b>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={6}>
                                <div className="text-center mt-1">
                                    <span><i style={{ color: '#DD1200' }} className="mdi mdi-circle font-size-18 me-1"></i> Pendientes</span>
                                    <div>
                                        <b className='font-size-20'>{pending}</b>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <div id="donut-chart" className="apex-charts">
                            <ReactApexChart options={chartData.options} series={chartData.series} type="donut" height="250" />
                        </div>
                    </CardBody>
                </Card>
            </React.Fragment>
        );
    }
}

export default Invoices;