import {
    GET_REFERRALS_SUCCESS,
    GET_REFERRALS_FAIL
} from "./actionTypes";

const INIT_STATE = {
    referrals: []
}

const Referrals = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_REFERRALS_SUCCESS:
            return {
                ...state,
                referrals: action.payload
            }

        case GET_REFERRALS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        default:
            return state;
    }
}

export default Referrals;